import { WorkflowParameterType } from '../pages/parameterConfig/ParameterModal';

export enum WorkflowType {
  Supplier = 0,
  Bid = 1,
  Procurement = 2,
  Contract = 3,
  Project = 4,
}

export const maskEmail = (email: string): string => {
  const [username, domain] = email.split('@');
  if (username.length <= 2) {
    return email; // No need to mask if the username is too short
  }

  // Mask the middle part of the username
  const maskedUsername =
    username[0] +
    '*'.repeat(username.length - 2) +
    username[username.length - 1];

  return `${maskedUsername}@${domain}`;
};

export const objToQueryParams = (params: any) => {
  //check if its an object (not array of function)
  if (params && params === Object(params)) {
    return '?' + new URLSearchParams(params).toString();
  }
  return null;
};

export const workflowTypeToString = (type: WorkflowType): string => {
  switch (type) {
    case WorkflowType.Supplier:
      return 'Supplier';
    case WorkflowType.Bid:
      return 'Bid';
    case WorkflowType.Procurement:
      return 'Procurement';
    case WorkflowType.Contract:
      return 'Contract';
    case WorkflowType.Project:
      return 'Project';
    default:
      return '';
  }
};

export const workflowParameterTypeToString = (
  type: WorkflowParameterType
): string => {
  switch (type) {
    case WorkflowParameterType.Int:
      return 'Integer';
    case WorkflowParameterType.String:
      return 'String';
    case WorkflowParameterType.List:
      return 'List';
    case WorkflowParameterType.Guid:
      return 'Guid';
    default:
      return '';
  }
};
