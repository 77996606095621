import axiosInstance from '../axiosInstance';
import { Endpoint } from '../endpoint/endpoint';
import { objToQueryParams } from './utilServices';

export const getWorkflows = async (queryParams: any) => {
  const params = objToQueryParams(queryParams);
  const url = Endpoint.workflow.get + params;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const createWorkflow = async (data: any) => {
  const url = Endpoint.workflow.create;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const updateWorkflow = async (id: string, data: any) => {
  const url = Endpoint.workflow.update;
  try {
    const response = await axiosInstance.put(url, data);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const deleteWorkflow = async (id: string) => {
  const url = Endpoint.workflow.delete;
  try {
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const getWorkflowTypes = async () => {
  const url = Endpoint.workflow.getTypes;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    // console.log(error);
  }
};

// Command Endpoints

export const createCommand = async (data: any) => {
  const url = Endpoint.workflow.command.create;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const updateCommand = async (data: any) => {
  const url = Endpoint.workflow.command.update;
  try {
    const response = await axiosInstance.put(url, data);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const getCommands = async (queryParams: any) => {
  const params = objToQueryParams(queryParams);
  const url = Endpoint.workflow.command.get + params;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const deleteCommand = async (workflowCommandId: string) => {
  const url = Endpoint.workflow.command.delete;

  const body = { workflowCommandId };
  try {
    const response = await axiosInstance.delete(url, { data: body });
    return response.data;
  } catch {
    // console.log(error);
  }
};

// Process Endpoints

export const createProcess = async (data: any) => {
  const url = Endpoint.workflow.process.create;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const updateProcess = async (id: string, data: any) => {
  const url = Endpoint.workflow.process.update;
  try {
    const response = await axiosInstance.put(url, data);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const getProcesses = async () => {
  const url = Endpoint.workflow.process.get;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const deleteProcess = async (id: string) => {
  const url = Endpoint.workflow.process.delete;
  try {
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch {
    // console.log(error);
  }
};

// Parameter Endpoints

export const createParameter = async (data: any) => {
  const url = Endpoint.workflow.parameter.create;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const updateParameter = async (data: any) => {
  const url = Endpoint.workflow.parameter.update;
  try {
    const response = await axiosInstance.put(url, data);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const getParameters = async (queryParams: any) => {
  const params = objToQueryParams(queryParams);
  const url = Endpoint.workflow.parameter.get + params;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const deleteParameter = async (workflowParameterId: string) => {
  const url = Endpoint.workflow.parameter.delete;
  const body = { workflowParameterId };
  try {
    const response = await axiosInstance.delete(url, { data: body });
    return response.data;
  } catch {
    // console.log(error);
  }
};

// Step Endpoints

export const createStep = async (data: any) => {
  const url = Endpoint.workflow.step.create;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const updateStep = async (id: string, data: any) => {
  const url = Endpoint.workflow.step.update;
  try {
    const response = await axiosInstance.put(url, data);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const getSteps = async () => {
  const url = Endpoint.workflow.step.get;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const deleteStep = async (id: string) => {
  const url = Endpoint.workflow.step.delete;
  try {
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch {
    // console.log(error);
  }
};
