import React from 'react';
import Module from '../Module';
import { operatorModulesData } from '../index';
import ProgressBar from '../../../shared/components/ProgressBar/ProgressBar';

const OperatorModule: React.FC = () => {
  return (
    <div>
      <Module
        title="Operator Module"
        desc="This is the Operator Module."
        modulesData={operatorModulesData}
      />
      <ProgressBar />
    </div>
  );
};

export default OperatorModule;
