import React, { useEffect, useState } from 'react';
import { CiFilter } from 'react-icons/ci';
import { FaSort } from 'react-icons/fa'; // Sort icon
import { BsThreeDotsVertical } from 'react-icons/bs'; // 3-dots icon
import ReactPaginate from 'react-paginate';
import Navbar from '../../shared/layouts/NavBar/Navbar';
import Sidebar from '../../shared/layouts/SideBar/Sidebar';
import ParameterModal, { WorkflowParameterType } from './ParameterModal'; // Importing the modal
import { useLocation } from 'react-router-dom';
import {
  deleteParameter,
  getParameters,
} from '../../services/workflowServices';
import { toast } from 'react-toastify';
import Delete from '../../shared/utils/Delete/Delete';
import { workflowParameterTypeToString } from '../../services/utilServices';

type ParameterItem = {
  id: number;
  parameterName: string;
  parameterType: string;
};

interface PageRequest {
  pageNumber: number;
  pageSize: number;
  workflowId: string;
}

export interface ParameterData {
  workflowParameterId: string;
  parameterName: string;
  parameterType: number;
  workflowId: string;
}

const parameterData: ParameterItem[] = [
  { id: 1, parameterName: 'Max Capacity', parameterType: 'Integer' },
  { id: 2, parameterName: 'Threshold Limit', parameterType: 'Float' },
  { id: 3, parameterName: 'Alert Type', parameterType: 'String' },
  // Add more data as needed
];

const ParameterConfig: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 2;
  const [selectedAll, setSelectedAll] = useState(false);
  const [parameterData, setParameterData] = useState<ParameterData[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [parameterItem, setParameterItem] = useState<ParameterData | null>(
    null
  );

  const [selectedItems, setSelectedItems] = useState<boolean[]>(
    parameterData.map(() => false)
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(
    null
  );
  const [isModalOpen, setModalOpen] = useState(false); // Modal state

  const location = useLocation();
  const workflowId = location.state?.workflowId;
  const pageRequest: PageRequest = {
    pageNumber: 1,
    pageSize: 10,
    workflowId: workflowId,
  };

  const toggleSelectAll = () => {
    const newSelected = !selectedAll;
    setSelectedAll(newSelected);
    setSelectedItems(parameterData.map(() => newSelected));
  };

  const toggleCheckbox = (index: number) => {
    const updatedSelectedItems = [...selectedItems];
    updatedSelectedItems[index] = !updatedSelectedItems[index];
    setSelectedItems(updatedSelectedItems);
  };

  const toggleDropdown = (index: number) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };

  const handlePageClick = (event: { selected: number }) => {
    setCurrentPage(event.selected);
  };

  const editModal = (parameter: ParameterData) => {
    setParameterItem(parameter);
    setModalOpen(true);
  };

  const deleteModal = (parameter: ParameterData) => {
    setParameterItem(parameter);
    setIsDeleteModalOpen(true);
  };

  const deleteSelectedCommand = async () => {
    if (!parameterItem) return;
    const { workflowParameterId } = parameterItem;
    const response: any = await deleteParameter(workflowParameterId);
    if (response.isSuccess) {
      toast.success(response?.data?.message);
      setIsDeleteModalOpen(false);
      fetchCommandList();
    }
  };

  const closeEditModal = () => {
    setModalOpen(false);
    setParameterItem(null);
    fetchCommandList();
  };

  const fetchCommandList = async () => {
    try {
      const response = await getParameters(pageRequest);
      if (response) {
        const parameterData = response.data.map((parameter: any) => ({
          ...parameter,
          parameterType: convertParameterType(parameter.parameterType),
        }));
        setParameterData(parameterData);
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  const convertParameterType = (
    parameterType: string
  ): WorkflowParameterType => {
    switch (parameterType) {
      case 'Int':
        return WorkflowParameterType.Int;
      case 'String':
        return WorkflowParameterType.String;
      case 'List':
        return WorkflowParameterType.List;
      case 'Guid':
        return WorkflowParameterType.Guid;
      default:
        return WorkflowParameterType.String; // Default to String if unknown type
    }
  };

  useEffect(() => {
    fetchCommandList();
  }, []);

  const filteredData = parameterData.filter((item) =>
    item.parameterName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const offset = currentPage * itemsPerPage;
  const currentItems = filteredData.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <>
       <div className="p-4 flex-1">
          {/* Title and Add button */}
          <div className="flex justify-between my-4">
            <h1 className="text-3xl mb-4">Parameter Configuration</h1>
            <button
              className="text-black px-4 py-2 border border-[#101828] text-[#101828] font-semibold rounded-lg"
              onClick={() => setModalOpen(true)} // Open the modal
            >
              + New Parameter
            </button>
          </div>

          {/* Search & Filter */}
          <div className="flex justify-end mb-4">
            <div className="flex justify-end items-center">
              <button className="p-2">
                <CiFilter size={24} />
              </button>
              <input
                type="text"
                placeholder="Search"
                className="border p-2 rounded "
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          {/* Table */}
          <table className="min-w-full table-auto border-collapse border border-gray-300">
            <thead className="bg-gray-200">
              <tr>
                {/* <th className="border">
                  <input
                    type="checkbox"
                    checked={selectedAll}
                    onChange={toggleSelectAll}
                    className="form-checkbox text-yellow-500 focus:ring-yellow-400"
                  />
                </th> */}
                <th className="border border-gray-300 px-4 py-2 text-left">
                  ID
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left">
                  Parameter Name
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left">
                  Parameter Type
                  <FaSort className="inline ml-1 cursor-pointer" />
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((parameter, index) => (
                <tr key={index}>
                  {/* <td className="border px-4 py-2">
                    <input
                      type="checkbox"
                      checked={selectedItems[index]}
                      onChange={() => toggleCheckbox(index)}
                      className="form-checkbox text-yellow-500 focus:ring-yellow-400"
                    />
                  </td> */}
                  <td className="border border-gray-300 px-4 py-2">
                    {parameter.workflowParameterId}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {parameter.parameterName}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {workflowParameterTypeToString(parameter.parameterType)}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 relative">
                    {/* 3-dots icon for dropdown */}
                    <button
                      onClick={() => toggleDropdown(index)}
                      className="text-gray-600"
                    >
                      <BsThreeDotsVertical />
                    </button>
                    {/* Dropdown menu */}
                    {openDropdownIndex === index && (
                      <div className="absolute  mt-2 w-40 bg-white border border-gray-300 rounded shadow-lg z-10">
                        <ul>
                          {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                            View
                          </li> */}
                          <li
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => editModal(parameter)}
                          >
                            Edit
                          </li>
                          <li
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-red-500"
                            onClick={() => deleteModal(parameter)}
                          >
                            Delete
                          </li>
                        </ul>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="flex justify-center items-center mt-4">
            <ReactPaginate
              previousLabel={'< Previous'}
              nextLabel={'Next >'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={'pagination items-center flex space-x-2'}
              activeClassName={'bg-black rounded text-white'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              disabledClassName={'disabled'}
              pageClassName={
                'page-item px-3 py-1 border rounded hover:bg-gray-200'
              }
              pageLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              nextLinkClassName={'page-link'}
              activeLinkClassName={'active-link'}
            />
          </div>

          {/* Modal for adding new parameters */}
          {isModalOpen && (
            <ParameterModal
              isOpen={isModalOpen}
              onClose={() => setModalOpen(false)}
              onEdit={closeEditModal}
              workflowId={workflowId}
              initialValues={parameterItem}
            />
          )}

          {isDeleteModalOpen && (
            <Delete
              title="Are you sure"
              desc="This action is permanent, all data would be lost."
              onDelete={deleteSelectedCommand}
              onCancel={() => setModalOpen(false)}
            />
          )}
        </div>
    </>
  );
};

export default ParameterConfig;
