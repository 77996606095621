import React, { useEffect, useState } from 'react';
import AwardModal from './AwardModal';
import ProjectModal from './ProjectModal';
import { useGlobalContext } from '../../../core/context/GlobalContext';
import {
  deleteAward,
  deleteProject,
  getAwards,
  getProjects,
} from '../../../services/supplierService';
import Delete from '../../../shared/utils/Delete/Delete';
import { toast } from 'react-toastify';
// import AwardModal from './AwardModal';

export interface ProjectInterface {
  projectId: string;
  projectName: string;
  projectValue: number;
  yearOfExecution: number;
  companyId: string;
  clientName: string;
}

export interface AwardInterface {
  awardId: string;
  awardName: string;
  issuedBy: string;
  issuedDate: string;
}
export const ProjectAndAwardTable = () => {
  const [isAwardModalOpen, setIsAwardModalOpen] = useState(false);
  const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAwardDeleteModalOpen, setIsAwardDeleteModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] =
    useState<ProjectInterface | null>(null);
  const [selectedAward, setSelectedAward] = useState<AwardInterface | null>(
    null
  );
  const [projects, setProjects] = useState<ProjectInterface[]>([]);
  const [awards, setAwards] = useState<AwardInterface[]>([]);
  const { supplierId } = useGlobalContext();

  const pageRequest = {
    pageNumber: 1,
    pageSize: 10,
  };

  const fetchProjectData = async () => {
    const fetchedId = supplierId || '';
    const response = await getProjects(fetchedId, pageRequest);
    if (response) setProjects(response.data);
  };

  const fetchAwardData = async () => {
    const fetchedId = supplierId || '';
    const response = await getAwards(fetchedId, pageRequest);
    if (response) setAwards(response.data);
  };

  const deleteProjectData = async () => {
    if (!selectedProject) return;
    const { projectId } = selectedProject;
    const response: any = await deleteProject(projectId);
    if (response.isSuccess) {
      toast.success('Project deleted successfully');
      setIsDeleteModalOpen(false);
      fetchProjectData();
    }
  };

  const deleteAwardData = async () => {
    if (!selectedAward) return;
    const { awardId } = selectedAward;
    const response: any = await deleteAward(awardId);
    if (response.isSuccess) {
      toast.success('Award deleted successfully');
      setIsAwardDeleteModalOpen(false);
      fetchAwardData();
    }
  };

  const openProjectModal = () => {
    setIsProjectModalOpen(true);
    setSelectedProject(null);
  };

  const editProject = (project: ProjectInterface) => {
    setSelectedProject(project);
    setIsProjectModalOpen(true);
  };

  const deleteProjectModal = (project: ProjectInterface) => {
    setSelectedProject(project);
    setIsDeleteModalOpen(true);
  };

  const closeProjectModal = () => {
    setIsProjectModalOpen(false);
    setSelectedProject(null);
    fetchProjectData();
  };

  const openAwardModal = () => {
    setIsAwardModalOpen(true);
    setSelectedAward(null);
  };

  const editAward = (award: AwardInterface) => {
    setSelectedAward(award);
    setIsAwardModalOpen(true);
  };

  const deleteAwardModal = (award: AwardInterface) => {
    setSelectedAward(award);
    setIsAwardDeleteModalOpen(true);
  };

  const closeAwardModal = () => {
    setIsAwardModalOpen(false);
    setSelectedAward(null);
    fetchAwardData();
  };

  const closeProjectDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedProject(null);
  };

  const closeAwardDeleteModal = () => {
    setIsAwardDeleteModalOpen(false);
    setSelectedAward(null);
  };

  useEffect(() => {
    fetchAwardData();
    fetchProjectData();
  }, []);

  return (
    <div>
      <div className="p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-bold">Projects*</h2>
          <button
            type="button"
            onClick={openProjectModal}
            className="text-black px-4 py-2 w-24 border border-[#101828] text-[#101828] font-semibold rounded-md"
          >
            + Add
          </button>
        </div>

        <table className="min-w-full table-auto border-collapse border border-gray-300">
          <thead className="bg-gray-200">
            <tr>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Name
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Client Name
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Year of Execution
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Project Value
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {projects.map((project: ProjectInterface) => (
              <tr key={project.projectId}>
                <td className="border border-gray-300 px-4 py-2">
                  {project.projectName}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {project.clientName}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {project.yearOfExecution}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {project.projectValue}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  <button
                    className="text-green-500"
                    onClick={() => editProject(project)}
                  >
                    Edit
                  </button>
                  <button
                    className="text-red-500 ml-2"
                    onClick={() => deleteProjectModal(project)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {isProjectModalOpen && (
          <ProjectModal
            onClose={closeProjectModal}
            initialValues={selectedProject}
          />
        )}

        {isDeleteModalOpen && (
          <Delete
            title="Are you sure"
            desc="This action is permanent, all data would be lost."
            onDelete={deleteProjectData}
            onCancel={closeProjectDeleteModal}
          />
        )}
      </div>
      <div className="p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-bold">Awards*</h2>
          <button
            onClick={openAwardModal}
            className="text-black px-4 py-2 w-24 border border-[#101828] text-[#101828] font-semibold rounded-md"
          >
            + Add
          </button>
        </div>

        <table className="min-w-full table-auto border-collapse border border-gray-300">
          <thead className="bg-gray-200">
            <tr>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Title of Award
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Issuing Body
              </th>

              <th className="border border-gray-300 px-4 py-2 text-left">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {awards.map((award: AwardInterface) => (
              <tr key={award.awardId}>
                <td className="border border-gray-300 px-4 py-2">
                  {award.awardName}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {award.issuedBy}
                </td>

                <td className="border border-gray-300 px-4 py-2">
                  <button
                    className="text-green-500"
                    onClick={() => editAward(award)}
                  >
                    Edit
                  </button>
                  <button
                    className="text-red-500 ml-2"
                    onClick={() => deleteAwardModal(award)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {isAwardModalOpen && (
          <AwardModal onClose={closeAwardModal} initialValues={selectedAward} />
        )}

        {isAwardDeleteModalOpen && (
          <Delete
            title="Are you sure"
            desc="This action is permanent, all data would be lost."
            onDelete={deleteAwardData}
            onCancel={closeAwardDeleteModal}
          />
        )}
      </div>
    </div>
  );
};
