import React from 'react';
import ApplicationProgress from './ApplicationProgressBar';
import { ModuleCard } from './CardList';
import Sidebar from '../../../shared/layouts/SideBar/Sidebar';
import { useNavigate } from 'react-router-dom';

const SupplierApplication = () => {
  const navigate = useNavigate();
  return (
    <div className="max-w-[1440px] mx-auto p-6">
        <h1 className="text-3xl font-bold my-4">Supplier Module </h1>
        <ApplicationProgress percentage={60} />
        <div className="mt-8">
          <div className="w-full p-4 bg-white shadow-md rounded-lg">
            <div className="flex justify-between items-center mb-2">
              <div>
                <h3 className="text-lg font-semibold">
                  Application Saved Progress
                </h3>
                <p className="text-gray-500">
                  This shows your supplier application verification progress
                </p>
              </div>
              <button
                className="bg-[#FFC726] text-black font-semibold w-24 h-12 p-2 rounded-lg"
                onClick={() =>
                  navigate('/supplier-registration', {
                    state: { isReadOnly: false },
                  })
                }
              >
                Edit
              </button>
            </div>
          </div>

          <div className="mt-8 w-full p-4 bg-white shadow-md rounded-lg">
            <div>
              <h3 className="text-xl font-semibold mb-2">Relevant Trainings</h3>
              <p className="text-gray-500 mb-4">
                These trainings will assist you in your onboarding process.
              </p>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                {ModuleCard.map((card) => (
                  <div
                    key={card.id}
                    className="p-4 bg-white shadow-md rounded-lg flex flex-col items-center justify-between"
                  >
                    <img
                      src={card.image}
                      alt={card.title}
                      className="w-full h-40 object-cover mb-4 rounded-md"
                    />
                    <h4 className="text-base font-semibold flex w-full justify-start text-center mb-2">
                      {card.title}
                    </h4>
                    <p className="text-sm text-gray-500 flex w-full justify start text-center">
                      {card.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default SupplierApplication;
