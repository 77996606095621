import React, { useEffect, useState } from 'react';
import ModalContactForm from './AddPrimaryContact';
import { useGlobalContext } from '../../../core/context/GlobalContext';
import { deleteContact, getContacts } from '../../../services/supplierService';
import Delete from '../../../shared/utils/Delete/Delete';
import { toast } from 'react-toastify';

export type PrimaryContact = {
  fullName: string;
  email: string;
  nationality: string;
  phoneNumber: string;
  contactId: string;
};
export const PrimaryContactTable = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [primaryContact, setPrimaryContact] = useState<PrimaryContact[]>([]);
  const [modalContact, setModalContact] = useState<PrimaryContact | null>(null);
  const { supplierId } = useGlobalContext();

  const pageRequest = {
    pageNumber: 1,
    pageSize: 10,
  };

  const fetchData = async () => {
    const fetchedId = supplierId || '';
    const response = await getContacts(fetchedId, pageRequest);
    if (response) setPrimaryContact(response.data);
  };

  const deletePrimaryContact = async () => {
    if (!modalContact) return;
    const { contactId } = modalContact;
    const response: any = await deleteContact(contactId);
    if (response.isSuccess) {
      toast.success('Contact deleted successfully');
      setIsDeleteModalOpen(false);
      fetchData();
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
    setModalContact(null);
  };
  const editModal = (contact: PrimaryContact) => {
    setIsModalOpen(true);
    setModalContact(contact);
  };
  const deleteModal = (contact: PrimaryContact) => {
    setModalContact(contact);
    setIsDeleteModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setModalContact(null);
    fetchData();
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setModalContact(null);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-bold">Primary Contact*</h2>
        <button
          onClick={openModal}
          className="text-black px-4 py-2 w-24 border border-[#101828] text-[#101828] font-semibold rounded-md"
        >
          + Add
        </button>
      </div>

      <table className="min-w-full table-auto border-collapse border border-gray-300">
        <thead className="bg-gray-200">
          <tr>
            <th className="border border-gray-300 px-4 py-2 text-left">
              {' '}
              Name
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left">
              Email
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left">
              Nationality
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left">
              Phone Number
            </th>

            <th className="border border-gray-300 px-4 py-2 text-left">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {primaryContact.map((contact, index) => (
            <tr key={index}>
              <td className="border border-gray-300 px-4 py-2">
                {contact.fullName}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {contact.email}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {contact.nationality}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {contact.phoneNumber}
              </td>

              <td className="border border-gray-300 px-4 py-2">
                <button
                  className="text-green-500"
                  onClick={() => editModal(contact)}
                >
                  Edit
                </button>
                <button
                  className="text-red-500 ml-2"
                  onClick={() => deleteModal(contact)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && (
        <ModalContactForm
          isOpen={isModalOpen}
          onClose={closeModal}
          initialValues={modalContact}
        />
      )}

      {isDeleteModalOpen && (
        <Delete
          title="Are you sure"
          desc="This action is permanent, all data would be lost."
          onDelete={deletePrimaryContact}
          onCancel={closeDeleteModal}
        />
      )}
    </div>
  );
};
