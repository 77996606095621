import React, { RefObject, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { KeyPersonnel } from './KeyPersonels';
import EducationForm from './Education';
import { useGlobalContext } from '../../../core/context/GlobalContext';
import {
  createPersonnel,
  updatePersonnel,
} from '../../../services/supplierService';
import EmploymentForm from './Employment';
import { toast } from 'react-toastify';
// import { useGlobalContext } from '../../../core/context/GlobalContext';
// import {
//   createPersonnel,
//   updatePersonnel,
// } from '../../../services/supplierService';

interface ModalPersonnelFormProps {
  isOpen: boolean;
  onClose: () => void;
  initialValues?: KeyPersonnel | null;
}

// Validation Schema using Yup
const validationSchema = Yup.object().shape({
  fullName: Yup.string().required('Key Personnel is required'),
  designation: Yup.string().required('Designation is required'),
  maritalStatus: Yup.string().required('Marital status is required'),
  phoneNumber: Yup.string().required('Phone number is required'),
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  stateOfOrigin: Yup.string().required('State of origin is required'),
  address: Yup.string().required('Address is required'),
  country: Yup.string().required('Country is required'),
  educationInformations: Yup.array().of(
    Yup.object().shape({
      // educationInformationId: Yup.string(),
      schoolName: Yup.string().required('University is required'),
      startYear: Yup.string().required('Year of entry is required'),
      endYear: Yup.string().required('Year of graduation is required'),
      // certificateAwarded: Yup.string().required('Field is required'),
      educationLevel: Yup.string().required('Education level is required'),
      major: Yup.string().required('Field is required'),
      // country: Yup.string().required('Country is required'),
    })
  ),
  workExperiences: Yup.array().of(
    Yup.object().shape({
      // workExperienceId: Yup.string(),
      companyName: Yup.string().required('Company is required'),
      appointmentDate: Yup.string().required('Start date is required'),
      disengagementDate: Yup.string().required('endDate is required'),
      jobTitle: Yup.string().required('Job title is required'),
      country: Yup.string().required('Country of work is required'),
      // roleDesc: Yup.string().required('Role description is required'),
    })
  ),
});

const KeyPersonnelModal: React.FC<ModalPersonnelFormProps> = ({
  onClose,
  initialValues,
}) => {
  const { supplierId } = useGlobalContext();
  const [step, setStep] = useState(1);
  const [shouldSubmit, setShouldSubmit] = useState(false);

  const proofInputRef = useRef<HTMLInputElement>(null);

  const isStep1Valid = () => {
    const requiredFields: (keyof KeyPersonnel)[] = [
      'fullName',
      'designation',
      'maritalStatus',
      'phoneNumber',
      'email',
      'stateOfOrigin',
      'address',
      'country',
    ];

    return requiredFields.every(
      (field) => formik.touched[field] && !formik.errors[field]
    );
  };

  const nextStep = () => {
    // if (step === 1) {
    //   if (!isStep1Valid()) return;
    // }

    // if (step === 2) {
    //   const educationInformationsValid = formik.values.educationInformations.every((education) => {
    //     return education.schoolName && education.startYear && education.endYear && education.educationLevel && education.major;
    //   });
    //   if (!educationInformationsValid) return;
    // }

    setShouldSubmit(false);
    setStep(step + 1);
  };

  const prevStep = () => setStep(step - 1);

  const handleSubmit = () => {
    setShouldSubmit(true);
    formik.submitForm();
  };

  const handleFileUploadClick = (inputRef: RefObject<HTMLInputElement>) => {
    if (inputRef.current) {
      inputRef.current.dispatchEvent(
        new MouseEvent('click', { bubbles: true, cancelable: true })
      );
    }
  };

  // Formik initial values
  // const formInitialValues = {

  const formik = useFormik<KeyPersonnel>({
    initialValues: initialValues
      ? {
          ...initialValues,
          educationInformations: initialValues.educationInformations.map(
            (education) => ({
              ...education,
              startYear: education.startYear
                ? new Date(education.startYear).toISOString().split('T')[0]
                : '',
              endYear: education.endYear
                ? new Date(education.endYear).toISOString().split('T')[0]
                : '',
            })
          ),
          workExperiences: initialValues.workExperiences.map((work) => ({
            ...work,
            appointmentDate: work.appointmentDate
              ? new Date(work.appointmentDate).toISOString().split('T')[0]
              : '',
            disengagementDate: work.disengagementDate
              ? new Date(work.disengagementDate).toISOString().split('T')[0]
              : '',
          })),
        }
      : {
          personalId: '',
          fullName: '',
          country: '',
          address: '',
          stateOfOrigin: '',
          maritalStatus: '',
          phoneNumber: '',
          email: '',
          designation: '',
          educationInformations: [
            {
              educationInformationId: '',
              schoolName: '',
              startYear: '',
              endYear: '',
              certificateAwarded: '',
              personalId: '',
              educationLevel: '',
              major: '',
            },
          ],
          workExperiences: [
            {
              workExperienceId: '',
              companyName: '',
              appointmentDate: '',
              disengagementDate: '',
              jobTitle: '',
              personalId: '',
              country: '',
              descriptions: '',
              isCurrentJob: false,
              proofOfEmployment: '',
            },
          ],
        },

    validationSchema: validationSchema,
    onSubmit: (values: KeyPersonnel) => {
      if (!shouldSubmit) return;
      const apiPayload = {
        companyId: supplierId,
        ...values,
      };

      // Call the API to create the supplier
      if (initialValues) {
        updateKeyPersonnel(apiPayload);
      } else {
        const { personalId, ...payloadWithoutPersonalId } = apiPayload;
        const refactoredPayload = {
          ...payloadWithoutPersonalId,
          educationInformations:
            payloadWithoutPersonalId.educationInformations.map((education) => {
              const {
                educationInformationId,
                personalId,
                certificateAwarded,
                ...educationWithoutId
              } = education;
              return {
                ...educationWithoutId,
                certificateAwarded: '',
              };
            }),
          workExperiences: payloadWithoutPersonalId.workExperiences.map(
            (workExperience) => {
              const {
                workExperienceId,
                personalId,
                proofOfEmployment,
                ...workExperienceWithoutId
              } = workExperience;
              return workExperienceWithoutId;
            }
          ),
        };
        createKeyPersonnel(refactoredPayload);
      }
    },
  });

  const createKeyPersonnel = async (payload: any) => {
    const response = await createPersonnel(payload);
    if (response?.isSuccess) {
      toast.success('Personnel created successfully');
      onClose();
    }
  };

  const updateKeyPersonnel = async (payload: any) => {
    const response = await updatePersonnel(payload);
    if (response?.isSuccess) {
      toast.success('Personnel updated successfully');
      onClose();
    }
  };

  const renderProgressBar = () => (
    <div className="flex justify-between items-center mb-4">
      <div className="flex items-center">
        <div
          className={`step ${
            step >= 1 ? 'bg-green-500 text-white' : 'bg-gray-300 text-gray-500'
          } w-8 h-8 rounded-full flex justify-center items-center`}
        >
          1
        </div>
        <span
          className={`ml-2 ${step >= 1 ? 'text-green-500' : 'text-gray-500'}`}
        >
          Basic Info
        </span>
      </div>
      <div className="flex items-center">
        <div className="border-t-2  border-gray-300 w-16 mx-2"></div>
        <div
          className={`step ${
            step >= 2 ? 'bg-green-500 text-white' : 'bg-gray-300 text-gray-500'
          } w-8 h-8 rounded-full flex justify-center items-center`}
        >
          2
        </div>
        <span
          className={`ml-2 ${step >= 2 ? 'text-green-500' : 'text-gray-500'}`}
        >
          Education
        </span>
      </div>
      <div className="flex items-center">
        <div className="border-t-2 border-gray-300 w-16 mx-2"></div>
        <div
          className={`step ${
            step === 3 ? 'bg-green-500 text-white' : 'bg-gray-300 text-gray-500'
          } w-8 h-8 rounded-full flex justify-center items-center`}
        >
          3
        </div>
        <span
          className={`ml-2 ${step === 3 ? 'text-green-500' : 'text-gray-500'}`}
        >
          Work Experience
        </span>
      </div>
    </div>
  );

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <div className="flex flex-col gap-x-4 gap-y-6  mb-4">
            <div className="w-full">
              <div>
                <label htmlFor="fullName">Key Personnel’s Name*</label>
                <input
                  name="fullName"
                  onChange={formik.handleChange}
                  value={formik.values.fullName}
                  placeholder="Enter Key Personnel’s Name"
                  className="w-full text-sm border h-10 rounded-lg"
                />
                {formik.errors.fullName && formik.touched.fullName && (
                  <span className="text-red-500">{formik.errors.fullName}</span>
                )}
              </div>
            </div>
            <div className="w-full">
              <div>
                <label htmlFor="designation">Designation*</label>
                <input
                  name="designation"
                  onChange={formik.handleChange}
                  value={formik.values.designation}
                  placeholder="Enter Designation"
                  className="w-full text-sm border h-10 rounded-lg"
                />
                {formik.errors.designation && formik.touched.designation && (
                  <span className="text-red-500">
                    {formik.errors.designation}
                  </span>
                )}
              </div>
            </div>
            <div className=" w-full gap-4 flex">
              <div className="w-1/3">
                <label htmlFor="maritalStatus">Marital Status*</label>
                <select
                  name="maritalStatus"
                  onChange={formik.handleChange}
                  value={formik.values.maritalStatus}
                  className="w-full text-sm border h-10 rounded-lg"
                >
                  <option value="">Select</option>
                  <option value="single">Single</option>
                  <option value="married">Married</option>
                </select>
                {formik.errors.maritalStatus &&
                  formik.touched.maritalStatus && (
                    <span className="text-red-500">
                      {formik.errors.maritalStatus}
                    </span>
                  )}
              </div>
              <div className="w-1/3">
                <label htmlFor="phoneNumber">Phone Number*</label>
                <input
                  name="phoneNumber"
                  onChange={formik.handleChange}
                  value={formik.values.phoneNumber}
                  placeholder="+1"
                  className="w-full text-sm border h-10 rounded-lg"
                />
                {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                  <span className="text-red-500">
                    {formik.errors.phoneNumber}
                  </span>
                )}
              </div>
              <div className="w-1/3">
                <label htmlFor="email">Email*</label>
                <input
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  placeholder="Enter Email"
                  className="w-full text-sm border h-10 rounded-lg"
                />
                {formik.errors.email && formik.touched.email && (
                  <span className="text-red-500">{formik.errors.email}</span>
                )}
              </div>
            </div>
            <div className="w-full gap-4 flex">
              <div className="w-1/3">
                <label htmlFor="stateOfOrigin">State of Origin*</label>
                <input
                  name="stateOfOrigin"
                  onChange={formik.handleChange}
                  value={formik.values.stateOfOrigin}
                  placeholder="Enter State of Origin"
                  className="w-full text-sm border h-10 rounded-lg"
                />
                {formik.errors.stateOfOrigin &&
                  formik.touched.stateOfOrigin && (
                    <div className="text-red-500">
                      {formik.errors.stateOfOrigin}
                    </div>
                  )}
              </div>
              <div className="w-1/3">
                <label htmlFor="address">Address*</label>
                <input
                  name="address"
                  onChange={formik.handleChange}
                  value={formik.values.address}
                  placeholder="Enter Address"
                  className="w-full text-sm border h-10 rounded-lg"
                />
                {formik.errors.address && formik.touched.address && (
                  <div className="text-red-500">{formik.errors.address}</div>
                )}
              </div>
              <div className="w-1/3">
                <label htmlFor="country">Country*</label>
                <select
                  name="country"
                  onChange={formik.handleChange}
                  value={formik.values.country}
                  className="w-full text-sm border h-10 rounded-lg"
                >
                  <option value="">Select</option>
                  <option value="nigeria">Nigeria</option>
                  <option value="ghana">Ghana</option>
                </select>
                {formik.errors.country && formik.touched.country && (
                  <div className="text-red-500">{formik.errors.country}</div>
                )}
              </div>{' '}
            </div>
          </div>
        );
      case 2:
        return <EducationForm parentFormik={formik} />;

      case 3:
        return <EmploymentForm parentFormik={formik} />;

      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white w-full max-w-4xl p-8 rounded-2xl relative">
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-500"
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/close.svg`}
            alt="close"
          />
        </button>
        <h2 className="text-lg font-bold mb-4">Add Key Personnel's Details</h2>

        {/* Divider */}
        <div className="divider my-6 !bg-[#E5E5E5]"></div>

        {renderProgressBar()}

        {/* Divider */}
        <div className="divider my-6 !bg-[#E5E5E5]"></div>

        <form onSubmit={formik.handleSubmit}>
          {renderStepContent()}

          {/* Divider */}
          <div className="divider my-8 !bg-[#E5E5E5]"></div>

          <div className="flex justify-end mt-4 gap-4">
            {step > 1 && (
              <button
                type="button"
                className="bg-gray-300 text-gray-700 px-12 h-12 py-2 rounded-lg"
                onClick={prevStep}
              >
                Back
              </button>
            )}
            {step < 3 ? (
              <button
                type="button"
                className="bg-primary text-black px-12 h-12 py-2 rounded-lg font-semibold"
                onClick={nextStep}
              >
                Next
              </button>
            ) : (
              <button
                type="submit"
                className="bg-primary text-black px-12 h-12 py-2 rounded-lg font-semibold"
                onClick={handleSubmit}
              >
                Save
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default KeyPersonnelModal;
