import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import FormComponent from './SupplierForm/SupplierRegistration';
import Sidebar from '../../shared/layouts/SideBar/Sidebar';
import { ShareholdersTable } from './SupplierForm/ShareHolders';
import { KeyPersonnelTable } from './SupplierForm/KeyPersonels';
import { PrimaryContactTable } from './SupplierForm/PrimaryContact';
import PricingComponent from './SupplierForm/Payments';
import { ProjectAndAwardTable } from './SupplierForm/ProjectAndAward';
import { useGlobalContext } from '../../core/context/GlobalContext';
import { AffiliateTable } from './SupplierForm/Affiliate';

const SupplierManagement: React.FC = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { supplierId } = useGlobalContext();

  // Define your tab titles
  const tabTitles = [
    'Company Information',
    'Affiliates',
    'Primary Contact',
    'Key Personnel',
    'Shareholders',
    'Projects and Awards',
    'Payments',
  ];

  // Calculate progress (in percentage)
  const progressPercentage = (selectedIndex / (tabTitles.length - 1)) * 100;

  return (
    <div className="p-6 w-full">
        <h1 className="font-bold text-3xl mb-6">Supplier Management</h1>
        {/* Progress Bar */}
        <div className="relative w-full h-4 bg-gray-200 rounded-full mb-6">
          <div
            className="absolute top-0 left-0 h-4 bg-[#1c8f3e] rounded-full transition-all duration-300"
            style={{ width: `${progressPercentage}%` }}
          />
        </div>

        {/* Tabs Component */}
        <Tabs
          selectedIndex={selectedIndex}
          onSelect={(index) => {
            if (index === 0 || supplierId) {
              setSelectedIndex(index);
            }
          }}
        >
          <TabList className="flex border-0">
            {tabTitles.map((title, index) => (
              <Tab
                key={index}
                className={`px-4 py-2 text-gray-600 ${index !== 0 && !supplierId ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                selectedClassName="bg-[#FFC726] font-bold text-black-500 rounded-t-lg"
                disabledClassName="text-gray-400"
                disabled={index !== 0 && !supplierId}
              >
                {title}
              </Tab>
            ))}
          </TabList>

          <TabPanel>
            <FormComponent />
          </TabPanel>

          <TabPanel>
            <AffiliateTable />
          </TabPanel>

          <TabPanel>
            <PrimaryContactTable />
          </TabPanel>

          <TabPanel>
            <KeyPersonnelTable />
          </TabPanel>

          <TabPanel>
            <ShareholdersTable />
          </TabPanel>

          <TabPanel>
            <ProjectAndAwardTable />
          </TabPanel>

          <TabPanel>
            <PricingComponent />
          </TabPanel>
        </Tabs>
      </div>
  );
};

export default SupplierManagement;
