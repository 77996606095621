import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './SideBar/Sidebar';
import Navbar from './NavBar/Navbar';

const ProtectedLayout: React.FC = () => {
  return (
    <div>
        <Navbar />
        <div className="overview-container gap-2">
            <Sidebar />
            <div className='h-full overflow-auto w-full'>
              {/* This will render the child routes */}
              <Outlet /> 
            </div>
        </div>
    </div>
  );
};

export default ProtectedLayout;
