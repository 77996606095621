import React, { useMemo } from 'react';
import { Formik, Form, Field } from 'formik';
import { ShareholderInterface } from './ShareHolders';
import { useGlobalContext } from '../../../core/context/GlobalContext';
import {
  createShareholder,
  updateShareholder,
} from '../../../services/supplierService';
import { t } from 'i18next';
import { toast } from 'react-toastify';

interface ModalShareholderFormProps {
  isOpen: boolean;
  onClose: () => void;
  initialValues?: ShareholderInterface | null;
}

export const AddShareholderModal: React.FC<ModalShareholderFormProps> = ({
  onClose,
  initialValues,
}) => {
  const { supplierId } = useGlobalContext();

  const formInitialValues = useMemo<ShareholderInterface>(() => {
    if (initialValues) {
      return {
        ...initialValues,
      };
    }
    return {
      shareholderId: '',
      fullName: '',
      maritalStatus: '',
      tin: '',
      identificationNumber: '',
      identificationType: '',
      percentageOwned: 0,
    };
  }, [initialValues]);

  const handleSubmit = async (values: ShareholderInterface) => {
    try {
      const payload = {
        ...values,
        companyId: supplierId,
      };

      if (initialValues) {
        payload.shareholderId = initialValues.shareholderId;
        const response = await updateShareholder(payload);
        if (response.isSuccess) {
          toast.success('Shareholder updated successfully');
          onClose();
        }
      } else {
        const { shareholderId, ...payloadWithoutShareholderId } = payload;
        payloadWithoutShareholderId.identificationNumber = '2222222';
        const response = await createShareholder({
          ...payloadWithoutShareholderId,
        });
        if (response.isSuccess) {
          toast.success('Shareholder created successfully');
          onClose();
        }
      }
    } catch (error) {
      toast.error('Failed to create shareholder');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white w-full max-w-4xl p-8 rounded-2xl relative">
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-500 text-3xl"
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/close.svg`}
            alt="close"
          />
        </button>
        <h2 className="text-lg font-bold mb-4">Add Shareholder's Detail</h2>

        <div className="divider my-6 !bg-[#E5E5E5]"></div>

        <Formik initialValues={formInitialValues} onSubmit={handleSubmit}>
          <Form>
            <div className="grid grid-cols-3 gap-x-4 gap-y-6 mb-4">
              <div className="col-span-3">
                <label
                  htmlFor="fullName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Shareholder's Name*
                </label>
                <Field
                  name="fullName"
                  placeholder="Enter Shareholder's name"
                  className="w-full border border-gray-300 h-[3rem] text-sm rounded-md focus:outline-none focus:border-gray-700"
                />
              </div>
              <div>
                <label
                  htmlFor="maritalStatus"
                  className="block text-sm font-medium text-gray-700"
                >
                  Marital Status*
                </label>
                <Field
                  as="select"
                  name="maritalStatus"
                  className="w-full border border-gray-300 h-[3rem] text-sm rounded-md focus:outline-none focus:border-gray-700"
                >
                  <option value="">Select</option>
                  <option value="single">Single</option>
                  <option value="married">Married</option>
                </Field>
              </div>
              <div>
                <label
                  htmlFor="tin"
                  className="block text-sm font-medium text-gray-700"
                >
                  Individual TIN*
                </label>
                <Field
                  name="tin"
                  placeholder="Enter Number"
                  className="w-full border border-gray-300 h-[3rem] text-sm rounded-md focus:outline-none focus:border-gray-700"
                />
              </div>
              <div>
                <label
                  htmlFor="identificationType"
                  className="block text-sm font-medium text-gray-700"
                >
                  Type of ID*
                </label>
                <Field
                  as="select"
                  name="identificationType"
                  className="w-full border border-gray-300 h-[3rem] text-sm rounded-md focus:outline-none focus:border-gray-700"
                >
                  <option value="">Select</option>
                  <option value="drivers_license">Driver's License</option>
                  <option value="passport">Passport</option>
                </Field>
              </div>
              <div>
                <label
                  htmlFor="percentageOwned"
                  className="block text-sm font-medium text-gray-700"
                >
                  $-% / Quota/Share*
                </label>
                <Field
                  name="percentageOwned"
                  placeholder="%"
                  className="w-full border border-gray-300 h-[3rem] text-sm rounded-md focus:outline-none focus:border-gray-700"
                />
              </div>
            </div>
            <div className="divider my-8 !bg-[#E5E5E5]"></div>
            <div className="flex justify-end">
              <button
                type="submit"
                className="bg-primary text-black px-12 h-12 py-2 rounded-lg font-semibold"
              >
                {initialValues ? 'Update' : 'Save'}
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};
