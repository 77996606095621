import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { LoginValidationSchema } from '../ValidationSchema';
import AppButton from '../../../shared/components/AppButton/AppButton';
import { FaArrowRight } from 'react-icons/fa';
import '../Login/Login.css';
import { useNavigate } from 'react-router-dom';
import Otp from '../../../shared/components/Otp/Otp';
import { Login as LoginService } from '../../../services/AccountServices';
import Navbar from '../../../shared/layouts/NavBar/Navbar';
import GoogleLoginButton from '../../../shared/components/GoogleLoginButton';
import { useGlobalContext } from '../../../core/context/GlobalContext';
import { toast } from 'react-toastify';

interface LoginFormValues {
  userName: string;
  password: string;
}

const Login: React.FC = () => {
  const { t } = useTranslation();
  const [showOtpModal, setShowOtpModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [loginError, setLoginError] = React.useState<string | null>(null);
  const navigate = useNavigate();
  const { login } = useGlobalContext();
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';

  // Clear local storage on component mount (refresh)
  useEffect(() => {
    localStorage.clear();
  }, []);

  const formik = useFormik({
    initialValues: {
      userName: '',
      password: '',
    },
    validationSchema: LoginValidationSchema,
    onSubmit: async (values: LoginFormValues) => {
      try {
        setIsLoading(true);
        const response = await LoginService(values);

        if (response) {
          localStorage.setItem('userId', response.data?.userId);
          localStorage.setItem('refreshToken', response.data?.refreshToken);
          localStorage.setItem('accessToken', response.data?.accessToken);
          localStorage.setItem('userName', response.data?.userName);
          login(); // Call context login to set the state and update localStorage

          if (response.requiresOtp) {
            setShowOtpModal(true);
          } else {
            toast.success('Login successful');
            navigate('/supplier-module');
          }
        }
      } catch {
        toast.error('Login failed. Please try again later.');
        setLoginError('Login failed. Please try again later.');
      }
      setIsLoading(false);
    },
  });

  return (
    <div>
      <Navbar />
      <div className="login-form">
        <div className="form-header">
          <h4 className="text-2xl font-semibold mt-5">
            {t(
              'welcomeToAnpEsscPortal',
              'Welcome to Autoridade Nacional Do Petroleo (ANP) Timor-Leste - ESSC PORTAL'
            )}
          </h4>
        </div>

        <form onSubmit={(e) => e.preventDefault()} className="login-card">
          <h1 className="text-2xl font-bold text-center mb-6">
            {t('login', 'Login')}
          </h1>

          {/* Social Login Buttons */}
          <div className="social-login-buttons">
            {/* <AppButton
              leftIcon={
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/login/goggle.png`}
                  alt="goggle"
                />
              }
              className="flex gap-2 items-center justify-center w-full text-[1rem] border border-gray-300 py-2 px-1 rounded-md"
              title="Google"
              onClick={() => handleExternalLogin('Google')}
            /> */}
            <GoogleOAuthProvider clientId={clientId}>
              <div className="">
                <GoogleLoginButton />
              </div>
            </GoogleOAuthProvider>
            <AppButton
              leftIcon={
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/login/microsoft.png`}
                  alt="microsoft"
                />
              }
              className="flex gap-2 items-center justify-center w-full text-[1rem] border border-gray-300 py-2 px-1 rounded-md"
              title="Microsoft 365"
              onClick={() => navigate('www.microsoft.com')}
            />
            <AppButton
              leftIcon={
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/login/outlook.png`}
                  alt="outlook"
                />
              }
              className="flex gap-2 items-center justify-center w-full text-[1rem] border border-gray-300 py-2 px-1 rounded-md"
              title="Outlook"
              onClick={() => navigate('www.outlook.com')}
            />
          </div>

          {/* Divider */}
          <div className="divider-container">
            <div className="divider"></div>
            <span className="divider-text">{t('or', 'OR')}</span>
            <div className="divider"></div>
          </div>

          {/* Login Form Fields */}
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              {t('usernameOrEmail', 'Username or email')}
            </label>
            <input
              type="text"
              id="userName"
              placeholder={t('enterUsernameOrEmail', 'Enter username or email')}
              name="userName"
              onChange={formik.handleChange}
              value={formik.values.userName}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            />
            {formik.touched.userName && formik.errors.userName && (
              <span className="text-red-500 text-sm">
                {formik.errors.userName}
              </span>
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              {t('password', 'Password')}
            </label>
            <input
              type="password"
              id="password"
              placeholder={t('enterPassword', 'Enter Password')}
              name="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            />
            {formik.touched.password && formik.errors.password && (
              <span className="text-red-500 text-sm">
                {formik.errors.password}
              </span>
            )}
          </div>

          {/* Submit Button */}
          <div className="flex justify-center mt-4">
            <AppButton
              className="w-full bg-[#FFC726] text-black font-semibold p-2 rounded-md"
              title={
                isLoading ? (
                  <ClipLoader size={20} color="#000" />
                ) : (
                  t('login', 'Login')
                )
              }
              onClick={() => formik.handleSubmit()}
              disabled={isLoading}
            />
          </div>

          {/* OTP Modal */}
          {showOtpModal && (
            <center>
              <Otp />
            </center>
          )}

          {/* Login Error Message */}
          {loginError && (
            <div className="text-red-500 text-sm text-center mt-2">
              {loginError}
            </div>
          )}

          {/* Forgot Password */}
          <div className="flex items-center gap-2 mt-4 justify-center">
            <AppButton
              className="text-xs border-b-2 border-black"
              title={t('forgotPassword', 'Forgot Password')}
              onClick={() => {
                navigate('/forgot-password');
              }}
            />
            <FaArrowRight size={10} />
          </div>

          {/* Copyright */}
          <div className="copyright">
            <h6>
              {t(
                'copyright',
                'Copyright  2024 ANP ESSC Portal. All rights reserved'
              )}
            </h6>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
