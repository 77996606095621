import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from 'react';

let setGlobalLoading: ((loading: boolean) => void) | null = null; // Global setter

interface GlobalContextProps {
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  isLoggedIn: boolean;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  supplierId: string | null;
  setSupplierId: (id: string | null) => void;
  login: () => void;
  logout: () => void;
}

interface GlobalProviderProps {
  children: ReactNode;
}

const GlobalContext = createContext<GlobalContextProps | undefined>(undefined);

export const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(() => {
    return localStorage.getItem('isAuthenticated') === 'true';
  });
  const [supplierId, setSupplierId] = useState<string | null>(() => {
    return localStorage.getItem('supplierId');
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // Update the global setter for loading
  setGlobalLoading = setIsLoading;

  const updateSupplierId = (id: string | null) => {
    setSupplierId(id);
    if (id) {
      localStorage.setItem('supplierId', id);
    } else {
      localStorage.removeItem('supplierId');
    }
  };

  const login = () => {
    setIsLoggedIn(true);
    localStorage.setItem('isAuthenticated', 'true');
  };

  const logout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('isAuthenticated');
    setSupplierId(null);
    localStorage.removeItem('supplierId');
  };

  useEffect(() => {
    const handleStorageChange = () => {
      setIsLoggedIn(localStorage.getItem('isAuthenticated') === 'true');
      setSupplierId(localStorage.getItem('supplierId'));
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        isLoading,
        setIsLoading,
        isOpen,
        setIsOpen,
        isLoggedIn,
        supplierId,
        setSupplierId: updateSupplierId,
        login,
        logout,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error('useGlobalContext must be used within a GlobalProvider');
  }
  return context;
};

// Export the global loading setter for external use
export const setGlobalLoadingState = (loading: boolean) => {
  if (setGlobalLoading) {
    setGlobalLoading(loading);
  }
};
