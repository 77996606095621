import React, { useState } from 'react';

const PricingComponent = () => {
  const [selectedValidity, setSelectedValidity] = useState('3 years');

  return (
    <div className="flex flex-col items-center p-4 sm:flex-row sm:justify-between">
      {/* Left Section */}
      <div className="space-y-4">
        <div className="flex items-center">
          <span className="mr-2 font-semibold">Choose Pack</span>
          <span className="bg-red-500 text-white px-2 py-1 rounded-md text-sm">
            PENDING
          </span>
        </div>

        <div className="font-bold text-lg">Enterprise classification: ESSC</div>

        {/* Features */}
        <div className="space-y-2">
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-green-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm4.5-10.5a1 1 0 10-1.4-1.4l-4.8 4.8-2.1-2.1a1 1 0 00-1.4 1.4l2.8 2.8a1 1 0 001.4 0l5.5-5.5z"
                clipRule="evenodd"
              />
            </svg>
            <span className="ml-2">Unlimited products & services</span>
          </div>
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-green-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm4.5-10.5a1 1 0 10-1.4-1.4l-4.8 4.8-2.1-2.1a1 1 0 00-1.4 1.4l2.8 2.8a1 1 0 001.4 0l5.5-5.5z"
                clipRule="evenodd"
              />
            </svg>
            <span className="ml-2">Unlimited products & services</span>
          </div>
        </div>
      </div>

      {/* Right Section */}
      <div className="mt-6 sm:mt-0">
        <div className="grid grid-cols-2 gap-4">
          <div
            className={`border rounded-lg p-4 cursor-pointer ${
              selectedValidity === '1 year'
                ? 'border-green-500'
                : 'border-gray-300'
            }`}
            onClick={() => setSelectedValidity('1 year')}
          >
            <div className="text-center">
              <div
                className={`${
                  selectedValidity === '1 year'
                    ? 'bg-green-500 text-white'
                    : 'bg-gray-100'
                } py-1 rounded-full px-2 inline-block text-sm`}
              >
                1 Year Validity
              </div>
              <div className="mt-2 font-bold text-xl">USD 1,000</div>
            </div>
          </div>

          <div
            className={`border rounded-lg p-4 cursor-pointer ${
              selectedValidity === '3 years'
                ? 'border-yellow-500'
                : 'border-gray-300'
            }`}
            onClick={() => setSelectedValidity('3 years')}
          >
            <div className="text-center">
              <div
                className={`${
                  selectedValidity === '3 years'
                    ? 'bg-yellow-500 text-white'
                    : 'bg-gray-100'
                } py-1 rounded-full px-2 inline-block text-sm`}
              >
                3 Years Validity
              </div>
              <div className="mt-2 font-bold text-xl">USD 2,250</div>
            </div>
          </div>
        </div>

        <div className="mt-6">
          <button className="bg-green-500 text-white py-2 px-4 rounded-lg w-full hover:bg-green-600">
            Pay
          </button>
        </div>
      </div>
    </div>
  );
};

export default PricingComponent;
