import React, { useRef, RefObject } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useGlobalContext } from '../../../core/context/GlobalContext';
import { createSupplier, updateSupplier } from '../../../services/supplierService';
import { toast } from 'react-toastify';
import { CompanyRegisterFormValues } from './SupplierRegistration';

interface ProjectModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialValues?: CompanyRegisterFormValues | null;
}

const validationSchema = Yup.object().shape({
  businessName: Yup.string().required('Business Name is required'),
  dateOfEstablishment: Yup.string().required(
    'Date of Establishment is required'
  ),
  typeOfBusiness: Yup.string().required('Type of Business is required'),
  country: Yup.string().required('Country is required'),
  registrationNumber: Yup.string().required(
    'Commercial Registration No is required'
  ),
  streetName: Yup.string().required(
    'Office Address Line 1 is required'
  ),
  officeAddress2: Yup.string(),
  region: Yup.string().required('Region is required'),
  city: Yup.string().required('City is required'),
  suburb: Yup.string().required('Suburb is required'),
  phoneNumber: Yup.string().required('Phone Number is required'),
  website: Yup.string().required('Website is required'),
  email: Yup.string().email('Invalid Email').required('Email is required'),
  // tin: Yup.string().required('Tax Identification Number is required'),
});

const AffiliateModal: React.FC<ProjectModalProps> = ({
  isOpen,
  onClose,
  initialValues,
}) => {
  const { setIsOpen } = useGlobalContext();
  const { supplierId } = useGlobalContext();
  // const { isLoading, setIsLoading } = useGlobalContext();
  // const fileInputRef = useRef<HTMLInputElement>(null);
  const commercialRegCertRef = useRef<HTMLInputElement>(null);
  const tinCertRef = useRef<HTMLInputElement>(null);
  const anotherTinCertRef = useRef<HTMLInputElement>(null);

  // Function to trigger the file input click
  const handleFileUploadClick = (inputRef: RefObject<HTMLInputElement>) => {
    if (inputRef.current) {
      inputRef.current.dispatchEvent(
        new MouseEvent('click', { bubbles: true, cancelable: true })
      );
    }
  };
  const formik = useFormik<CompanyRegisterFormValues>({
    initialValues: initialValues
    ? {
      ...initialValues,
      dateOfEstablishment: initialValues.dateOfEstablishment
        ? new Date(initialValues.dateOfEstablishment).toISOString()
        : '',
    }
    : {
      businessName: '',
      dateOfEstablishment: '',
      typeOfBusiness: '',
      country: '',
      registrationNumber: '',
      streetName: '',
      officeAddress2: '',
      region: '',
      city: '',
      suburb: '',
      phoneNumber: '',
      website: '',
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const payload = {
        companyId: values.companyId,
        businessName: values.businessName,
        establishmentDate: values.dateOfEstablishment
          ? new Date(values.dateOfEstablishment).toISOString()
          : new Date().toISOString(),
        streetName: values.streetName,
        region: values.region,
        city: values.city,
        registrationNumber: values.registrationNumber,
        activate: true,
        town: values.suburb,
        phoneNumber: values.phoneNumber,
        website: values.website,
        email: values.email,
        supplierCategory: 'affiliate',
        parentCompanyId: supplierId,
      };

      const formData = new FormData();
      Object.keys(payload).forEach((key) => {
        if (payload[key as keyof typeof payload] !== undefined) {
          formData.append(
            key as string,
            payload[key as keyof typeof payload] as string | Blob
          );
        }
      });
      // if (values.commercialRegCertFile) {
      //   formData.append(
      //     'commercialRegCertFile',
      //     values.commercialRegCertFile
      //   );
      // }
      // if (values.taxIdentificationFile) {
      //   formData.append(
      //     'taxIdentificationFile',
      //     values.taxIdentificationFile
      //   );
      // }
      // if (values.identificationFile) {
      //   formData.append('identificationFile', values.identificationFile);
      // }

      if(initialValues) {
        updateAffiliate(payload);
      } else {
        delete payload.companyId;
        createAffiliate(payload);
      }
    },
  });

  const createAffiliate = async (payload: any) => {
    try {
      const response = await createSupplier(payload);
      if (response.isSuccess) {
        toast.success(response?.data?.message);
        onClose();
        setIsOpen(false);
      }
    } catch {
      toast.error('Failed to create supplier affiliate');
    }
  };

  const updateAffiliate = async (payload: any) => {
    console.log('payload', payload);
    try {
      const response = await updateSupplier(payload);
      if (response.isSuccess) {
        toast.success(response?.data?.message);
        onClose();
        setIsOpen(false);
      }
    } catch {
      toast.error('Failed to create supplier affiliate');
    }
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-2xl shadow-lg w-full max-w-5xl scrollable-modal">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">
                Add Affiliates and Subsidiaries
              </h2>
              <button
                className="text-red-500 text-xl"
                onClick={onClose}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/close.svg`}
                  alt="close"
                />
              </button>
            </div>

            {/* Divider */}
            <div className="col-span-6 divider my-6 !bg-[#E5E5E5]"></div>

            <div className="grid grid-cols-6 gap-x-4 gap-y-8">
              {/* Row 1: Business Name, Date of Establishment, Type of Business */}
              <div className="col-span-2">
                <label className="block mb-1">
                  Business Name<span className="text-red-500">*</span>
                </label>
                <input
                  value={formik.values.businessName}
                  onChange={formik.handleChange('businessName')}
                  name="businessName"
                  className="w-full border rounded-lg text-sm p-2 h-11"
                  placeholder="Enter business name"
                />
                {formik.touched.businessName && formik.errors.businessName && (
                  <span className="text-red-500 text-sm">
                    {formik.errors.businessName}
                  </span>
                )}
              </div>

              <div className="col-span-2">
                <label className="block mb-1">
                  Date of Establishment<span className="text-red-500">*</span>
                </label>
                <input
                  value={formik.values.dateOfEstablishment}
                  onChange={formik.handleChange('dateOfEstablishment')}
                  type="date"
                  name="dateOfEstablishment"
                  className="w-full border rounded-lg p-2 h-11"
                />
                {formik.errors.dateOfEstablishment &&
                  formik.touched.dateOfEstablishment && (
                    <span className="text-red-600">
                      {formik.errors.dateOfEstablishment}
                    </span>
                  )}
              </div>

              <div className="col-span-2">
                <label className="block mb-1">
                  Type of Business<span className="text-red-500">*</span>
                </label>
                <select
                  value={formik.values.typeOfBusiness}
                  onChange={formik.handleChange('typeOfBusiness')}
                  name="typeOfBusiness"
                  className="w-full border rounded-lg p-2 h-11"
                >
                  <option value="">Select Type of Business</option>
                  <option value="sole proprietorship">Sole Proprietorship</option>
                  <option value="partnership">Partnership</option>
                  <option value="corporation">Corporation</option>
                  <option value="limited liability company">LLC</option>
                </select>
                {formik.errors.typeOfBusiness &&
                  formik.touched.typeOfBusiness && (
                    <span className="text-red-600">
                      {formik.errors.typeOfBusiness}
                    </span>
                  )}
              </div>

              {/* Row 2: Country, Commercial Registration No, Office Address */}
              <div className="col-span-3">
                <label className="block mb-1">
                  Country<span className="text-red-500">*</span>
                </label>
                <select
                  value={formik.values.country}
                  onChange={formik.handleChange('country')}
                  name="country"
                  className="w-full border rounded-lg p-2 h-11"
                >
                  <option value="">Select Country</option>
                  <option value="US">United States</option>
                  <option value="UK">United Kingdom</option>
                  <option value="NG">Nigeria</option>
                  <option value="CA">Canada</option>
                  {/* Add more countries as needed */}
                </select>
                {formik.errors.country && formik.touched.country && (
                  <span className="text-red-600">{formik.errors.country}</span>
                )}
              </div>

              <div className="col-span-3">
                <label className="block mb-1">
                  Commercial Reg. No<span className="text-red-500">*</span>
                </label>
                <select
                  value={formik.values.registrationNumber}
                  onChange={formik.handleChange('registrationNumber')}
                  name="registrationNumber"
                  className="w-full border rounded-lg p-2 h-11"
                >
                  <option value="">Select Registration Number</option>
                  <option value="001">001</option>
                  <option value="002">002</option>
                  <option value="003">003</option>
                  {/* Add more options as needed */}
                </select>
                {formik.errors.registrationNumber && formik.touched.region && (
                  <span className="text-red-600">
                    {formik.errors.registrationNumber}
                  </span>
                )}
              </div>

              <div className="col-span-3">
                <label className="block mb-1">
                  Office Address Line 1<span className="text-red-500">*</span>
                </label>
                <input
                  value={formik.values.streetName}
                  onChange={formik.handleChange('streetName')}
                  name="streetName"
                  className=" text-sm w-full border rounded-lg p-2 h-11"
                  placeholder="Enter address line 1"
                />
                {formik.errors.streetName &&
                  formik.touched.streetName && (
                    <span className="text-red-600">
                      {formik.errors.streetName}
                    </span>
                  )}
              </div>

              <div className="col-span-3">
                <label className="block mb-1">Office Address Line 2</label>
                <input
                  value={formik.values.officeAddress2}
                  onChange={formik.handleChange('officeAddress2')}
                  name="officeAddress2"
                  className=" text-sm w-full border rounded-lg p-2 h-11"
                  placeholder="Enter address line 2"
                />

                {formik.errors.officeAddress2 &&
                  formik.touched.officeAddress2 && (
                    <span className="text-red-600">
                      {formik.errors.officeAddress2}
                    </span>
                  )}
              </div>

              {/* Row 3: Region, City, Suburb */}
              <div className="col-span-2">
                <label className="block mb-1">
                  Region<span className="text-red-500">*</span>
                </label>
                <select
                  value={formik.values.region}
                  onChange={formik.handleChange('region')}
                  name="region"
                  className="w-full border rounded-lg p-2 h-11"
                >
                  <option value="">Select Region</option>
                  <option value="north">North</option>
                  <option value="south">South</option>
                  <option value="east">East</option>
                  <option value="west">West</option>
                </select>
                {formik.errors.region && formik.touched.region && (
                  <span className="text-red-600">{formik.errors.region}</span>
                )}
              </div>

              <div className="col-span-2">
                <label className="block mb-1">
                  City<span className="text-red-500">*</span>
                </label>
                <input
                  value={formik.values.city}
                  onChange={formik.handleChange('city')}
                  name="city"
                  className=" text-sm w-full border rounded-lg p-2 h-11"
                  placeholder="Enter city"
                />
                {formik.errors.city && formik.touched.city && (
                  <span className="text-red-600">{formik.errors.city}</span>
                )}
              </div>

              <div className="col-span-2">
                <label className="block mb-1">
                  Suburb<span className="text-red-500">*</span>
                </label>
                <select
                  value={formik.values.suburb}
                  onChange={formik.handleChange('suburb')}
                  name="suburb"
                  className="w-full border rounded-lg p-2 h-11"
                >
                  <option value="">Select Suburb</option>
                  <option value="urban">Urban</option>
                  <option value="rural">Rural</option>
                </select>
                {formik.errors.suburb && formik.touched.suburb && (
                  <span className="text-red-600">{formik.errors.suburb}</span>
                )}
              </div>

              {/* Row 4: Phone Number, Website, Email */}
              <div className="col-span-2">
                <label className="block mb-1">
                  Phone Number<span className="text-red-500">*</span>
                </label>
                <input
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange('phoneNumber')}
                  name="phoneNumber"
                  className=" text-sm w-full border rounded-lg p-2 h-11"
                  placeholder="Enter phone number"
                />
                {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                  <span className="text-red-600">
                    {formik.errors.phoneNumber}
                  </span>
                )}
              </div>
              <div className="col-span-2">
                <label className="block mb-1">
                  Website<span className="text-red-500">*</span>
                </label>
                <input
                  value={formik.values.website}
                  onChange={formik.handleChange('website')}
                  name="website"
                  className=" text-sm w-full border rounded-lg p-2 h-11"
                  placeholder="Enter website URL"
                />
                {formik.errors.website && formik.touched.website && (
                  <span className="text-red-600">{formik.errors.website}</span>
                )}
              </div>
              <div className="col-span-2">
                <label className="block mb-1">
                  Email<span className="text-red-500">*</span>
                </label>
                <input
                  value={formik.values.email}
                  onChange={formik.handleChange('email')}
                  type="email"
                  name="email"
                  className=" text-sm w-full border rounded-lg p-2 h-11"
                  placeholder="Enter email"
                />
                {formik.errors.email && formik.touched.email && (
                  <span className="text-red-600">{formik.errors.email}</span>
                )}
              </div>

              {/* File Uploads */}
              <div className="col-span-6">
                <div className="verification-upload mb-4">
                  <label className="block mb-1">
                    Commercial Registration Certificate
                  </label>
                  <div className="flex items-center gap-2">
                    <input
                      ref={commercialRegCertRef}
                      className="border p-2 rounded-lg w-full"
                      type="file"
                      name="commercialRegCertFile"
                    />
                    <button
                      type="button"
                      onClick={() => handleFileUploadClick(commercialRegCertRef)}
                      className="bg-green-500 text-white h-14 px-4 rounded-lg"
                    >
                      Upload
                    </button>
                  </div>
                  <span className="text-gray-500 text-sm">
                    Please upload .jpg, .png or .pdf, less than 100KB
                  </span>
                </div>

                <div className="verification-upload mb-4">
                  <label className="block mb-1">
                    Tax Identification Number (TIN) Certificate
                  </label>
                  <div className="flex items-center gap-2">
                    <input
                      ref={tinCertRef}
                      className="border p-2 rounded-lg w-full"
                      type="file"
                      name="tinCertFile"
                    />
                    <button
                      type="button"
                      onClick={() => handleFileUploadClick(tinCertRef)}
                      className="bg-green-500 text-white h-14 px-4 rounded-lg"
                    >
                      Upload
                    </button>
                  </div>
                  <span className="text-gray-500 text-sm">
                    Please upload .jpg, .png or .pdf, less than 100KB
                  </span>
                </div>
                <div className="verification-upload mb-4">
                  <label className="block mb-1">
                    Tax Identification Number (TIN) Certificate
                  </label>
                  <div className="flex items-center gap-2">
                    <input
                      className="border p-2 rounded-lg w-full"
                      type="file"
                      name="tinCertFile"
                      ref={anotherTinCertRef}
                    />
                    <button
                      type="button"
                      onClick={() => handleFileUploadClick(anotherTinCertRef)}
                      className="bg-green-500 text-white h-14 px-4 rounded-lg"
                    >
                      Upload
                    </button>
                  </div>
                  <span className="text-gray-500 text-sm">
                    Please upload .jpg, .png or .pdf, less than 100KB
                  </span>
                </div>
              </div>

              {/* Divider */}
              <div className="col-span-6 divider my-6 !bg-[#E5E5E5]"></div>

              {/* Submit Button */}
              <div className="col-span-6 flex justify-end mt-4">
                {/* <button
                  className="bg-gray-500 text-white px-6 py-2 rounded-lg"
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </button> */}
                <button
                  className="bg-primary text-black px-12 h-12 py-2 rounded-lg font-semibold"
                  onClick={() => formik.handleSubmit()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default AffiliateModal;
