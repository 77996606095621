import React from 'react';
import './ProgressBar.css';

const ProgressBar: React.FC = () => {
  const steps = [
    "Reviewer 1's Desk",
    "Reviewer 2's Desk",
    "Reviewer 3's Desk",
    "Approver's Desk",
  ];

  const currentStep: number = 1; // This indicates progress up to "Reviewer 2's Desk"

  return (
    <div className="cardBoard flex flex-col justify-start">
      <div className="mb-4 ml-10">
        <p className="text-lg font-semibold text-gray-700">
          Application Progress
        </p>
        <p className="text-sm text-gray-500">
          This shows your application verification progress
        </p>
      </div>

      <div className="w-[600px]">
        <div className="progress-container">
          {steps.map((step, index) => (
            <div className="step-container" key={index}>
              <div
                className={`circle ${index <= currentStep ? 'completed' : ''}`}
              >
                {index + 1}
              </div>
              <p className="step-label text-sm text-gray-500">{step}</p>
              {index < steps.length - 1 && (
                <div
                  className={`line ${index < currentStep ? 'completed' : ''}`}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
