import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from '../pages/Auth/Login/Login';
import PageNotFound from '../shared/utils/WildCard/PageNotFound';
import SignUp from '../pages/Auth/SignUp/SignUp';
import Otp from '../shared/components/Otp/Otp';
import SuccessCard from '../shared/utils/SuccessCard/SuccessCard';
import ForgetPassword from '../pages/Auth/Forget-Password/ForgetPassword';
import PasswordReset from '../pages/Auth/Forget-Password/PassswordReset';
import ChooseDemo from '../pages/ChooseDemo/ChooseDemo';

import SupplierModule from '../pages/module/supplierModule/SupplierModule';
import OperatorModule from '../pages/module/operatorModule/OperatorModule';
import Sidebar from '../shared/layouts/SideBar/Sidebar';
import SupplierOverView from '../pages/SupplierOverView/SupplierOverView';
import SupplierManagement from '../pages/SupplierManagement/SupplierManagement';
import SupplierApplication from '../pages/SupplierManagement/SupplierApplication/SupplierApplication';
import ProtectedRoute from '../core/guard/ProtectedRoute';
import WorkflowConfiguration from '../pages/workFlowConfig/workflowConfig';
import ParameterConfig from '../pages/parameterConfig/ParameterConfig';
import CommandConfig from '../pages/commandConfig/CommandConfig';
import SupplierApplicationWorkFlow from '../pages/SupplierApplicationWorkflow/SupplierApplicationWorkFlow';
import StepConfig from '../pages/workFlowConfig/StepConfig';
import ProtectedLayout from '../shared/layouts/ProtectedLayout';

const RouterConfig: React.FC = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/success-card" element={<SuccessCard title="Success" />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<SignUp />} />
      <Route path="/otp" element={<Otp />} />
      <Route path="forgot-password/password-reset" element={<PasswordReset />} />
      <Route path="/forgot-password" element={<ForgetPassword />} />
      <Route path="/choose-demo" element={<ChooseDemo />} />
      <Route path="/supplier-module" element={<SupplierModule />} />

      {/* Protected Routes with Shared Layout */}
      <Route
        element={
          <ProtectedRoute>
            <ProtectedLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/dashboard" element={<SupplierOverView />} />
        <Route path="/supplier-registration" element={<SupplierManagement />} />
        <Route path="/operator-module" element={<OperatorModule />} />
        <Route path="/supplier-application" element={<SupplierApplication />} />
        <Route path="/workflow" element={<WorkflowConfiguration />} />
        <Route path="/workflow/parameter" element={<ParameterConfig />} />
        <Route path="/workflow/command" element={<CommandConfig />} />
        <Route path="/workflow/supplier-application" element={<SupplierApplicationWorkFlow />} />

        {/* Wildcard Route */}
        <Route path="*" element={<PageNotFound />} />
      </Route>

      {/* Wildcard Route */}
      {/* <Route path="*" element={<PageNotFound />} /> */}
    </Routes>
  );
};

export default RouterConfig;
