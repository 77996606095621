import React, { useEffect, useState } from 'react';
import { AddShareholderModal } from './AddShareholderModal';
import { useGlobalContext } from '../../../core/context/GlobalContext';
import {
  deleteShareholder,
  getShareholders,
} from '../../../services/supplierService';
import Delete from '../../../shared/utils/Delete/Delete';
import { toast } from 'react-toastify';

export interface ShareholderInterface {
  shareholderId: string;
  fullName: string;
  maritalStatus: string;
  tin: string;
  identificationNumber: string;
  identificationType: string;
  percentageOwned: number;
  // companyId: string;
}

export const ShareholdersTable = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedShareHolder, setSelectedShareHolder] =
    useState<ShareholderInterface | null>(null);
  const [shareHolders, setShareHolders] = useState<ShareholderInterface[]>([]);
  const { supplierId } = useGlobalContext();

  const pageRequest = {
    pageNumber: 1,
    pageSize: 10,
  };

  const fetchData = async () => {
    const fetchedId = supplierId || '';
    const response = await getShareholders(fetchedId, pageRequest);
    if (response) setShareHolders(response.data);
  };

  const deleteSelectedShareholder = async () => {
    if (!selectedShareHolder) return;
    const { shareholderId } = selectedShareHolder;
    const response: any = await deleteShareholder(shareholderId);
    if (response.isSuccess) {
      toast.success('Shareholder deleted successfully');
      setIsDeleteModalOpen(false);
      fetchData();
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
    setSelectedShareHolder(null);
  };

  const editShareholder = (shareholder: ShareholderInterface) => {
    setSelectedShareHolder(shareholder);
    setIsModalOpen(true);
  };

  const deleteModal = (shareholder: ShareholderInterface) => {
    setSelectedShareHolder(shareholder);
    setIsDeleteModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedShareHolder(null);
    fetchData();
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedShareHolder(null);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-bold">Shareholders*</h2>
        <button
          onClick={openModal}
          className="text-black px-4 py-2 w-24 border border-[#101828] text-[#101828] font-semibold rounded-md"
        >
          + Add
        </button>
      </div>

      <table className="min-w-full table-auto border-collapse border border-gray-300">
        <thead className="bg-gray-200">
          <tr>
            <th className="border border-gray-300 px-4 py-2 text-left">
              Shareholders Name
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left">
              Marital Status
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left">
              Individual TIN
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left">
              ID Number
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left">
              Type of ID
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left">
              $-% / Quota/Shares
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {shareHolders.map((shareholder: ShareholderInterface) => (
            <tr key={shareholder.shareholderId}>
              <td className="border border-gray-300 px-4 py-2">
                {shareholder.fullName}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {shareholder.maritalStatus}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {shareholder.tin}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {shareholder.identificationNumber}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {shareholder.identificationType}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {shareholder.percentageOwned}
              </td>
              <td className="border border-gray-300 px-4 py-2 text-left">
                <button
                  onClick={() => editShareholder(shareholder)}
                  className="text-green-500 hover:text-green-700"
                >
                  Edit
                </button>
                <button
                  onClick={() => deleteModal(shareholder)}
                  className="text-red-500 hover:text-red-700 ml-2"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && (
        <AddShareholderModal
          isOpen={isModalOpen}
          onClose={closeModal}
          initialValues={selectedShareHolder}
        />
      )}

      {isDeleteModalOpen && (
        <Delete
          title="Are you sure"
          desc="This action is permanent, all data would be lost."
          onDelete={deleteSelectedShareholder}
          onCancel={closeDeleteModal}
        />
      )}
    </div>
  );
};
