import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import KeyPersonnelModal from './AddPersonnelModal';
import { useGlobalContext } from '../../../core/context/GlobalContext';
import {
  deletePersonnel,
  getPersonnels,
} from '../../../services/supplierService';
import Delete from '../../../shared/utils/Delete/Delete';

export interface EducationInformation {
  educationInformationId: string;
  schoolName: string;
  startYear: string;
  endYear: string;
  certificateAwarded: string;
  personalId: string;
  educationLevel: string;
  major: string;
}

export interface WorkExperience {
  workExperienceId: string;
  companyName: string;
  appointmentDate: string;
  disengagementDate: string;
  jobTitle: string;
  personalId: string;
  country: string;
  descriptions: string;
  isCurrentJob: boolean;
  proofOfEmployment: string;
}

export interface KeyPersonnel {
  personalId: string;
  fullName: string;
  country: string;
  address: string;
  stateOfOrigin: string;
  maritalStatus: string;
  phoneNumber: string;
  email: string;
  designation: string;
  educationInformations: EducationInformation[];
  workExperiences: WorkExperience[];
}
export const KeyPersonnelTable = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedPersonnel, setSelectedPersonnel] =
    useState<KeyPersonnel | null>(null);
  const [keyPersonnel, setKeyPersonnel] = useState<KeyPersonnel[]>([]);
  const { supplierId } = useGlobalContext();

  const pageRequest = {
    pageNumber: 1,
    pageSize: 10,
  };

  const fetchData = async () => {
    const fetchedId = supplierId || '';
    const response = await getPersonnels(fetchedId, pageRequest);
    if (response) setKeyPersonnel(response.data);
  };

  const deleteKeyPersonnel = async () => {
    if (!selectedPersonnel) return;

    const response: any = await deletePersonnel(selectedPersonnel?.personalId);
    if (response.isSuccess) {
      toast.success('Personnel deleted successfully');
      setIsDeleteModalOpen(false);
      fetchData();
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
    setSelectedPersonnel(null);
  };

  const editPersonnel = (personnel: KeyPersonnel) => {
    setSelectedPersonnel(personnel);
    setIsModalOpen(true);
  };

  const deleteModal = (personnel: KeyPersonnel) => {
    setSelectedPersonnel(personnel);
    setIsDeleteModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedPersonnel(null);
    fetchData();
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedPersonnel(null);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-bold">Key Personnel</h2>
        <button
          onClick={openModal}
          className="text-black px-4 py-2 w-24 border border-[#101828] text-[#101828] font-semibold rounded-md"
        >
          + Add
        </button>
      </div>

      <table className="min-w-full table-auto border-collapse border border-gray-300">
        <thead className="bg-gray-200">
          <tr>
            <th className="border border-gray-300 px-4 py-2 text-left">
              Key Personnel Name
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left">
              Marital Status
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left">
              Email
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left">
              Phone Number
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left">
              Education
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {keyPersonnel.map((personnel: KeyPersonnel) => (
            <tr key={personnel.personalId}>
              <td className="border border-gray-300 px-4 py-2 text-left">
                {personnel.fullName}
              </td>
              <td className="border border-gray-300 px-4 py-2 text-left">
                {personnel.maritalStatus}
              </td>
              <td className="border border-gray-300 px-4 py-2 text-left">
                {personnel.email}
              </td>
              <td className="border border-gray-300 px-4 py-2 text-left">
                {personnel.phoneNumber}
              </td>
              <td className="border border-gray-300 px-4 py-2 text-left">
                {personnel.educationInformations.length > 0
                  ? personnel.educationInformations
                      .map((edu) => edu.educationLevel)
                      .join(', ')
                  : ''}
              </td>
              <td className="border border-gray-300 px-4 py-2 text-left">
                <button
                  onClick={() => editPersonnel(personnel)}
                  className="text-green-500 hover:text-green-700"
                >
                  Edit
                </button>
                <button
                  onClick={() => deleteModal(personnel)}
                  className="text-red-500 hover:text-red-700 ml-2"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && (
        <KeyPersonnelModal
          isOpen={isModalOpen}
          onClose={closeModal}
          initialValues={selectedPersonnel}
        />
      )}

      {isDeleteModalOpen && (
        <Delete
          title="Are you sure"
          desc="This action is permanent, all data would be lost."
          onDelete={deleteKeyPersonnel}
          onCancel={closeDeleteModal}
        />
      )}
    </div>
  );
};
