export interface MenuItem {
  id: number;
  name: string;
  link: string;
  icon: string;
}

export const menuItems: MenuItem[] = [
  {
    id: 1,
    name: 'Home',
    link: '/dashboard',
    icon: `${process.env.PUBLIC_URL}/assets/icons/outline/home.svg`,
  },
  {
    id: 2,
    name: 'Supplier Management',
    link: '/supplier-application',
    icon: `${process.env.PUBLIC_URL}/assets/icons/outline/people.svg`,
  },
  {
    id: 3,
    name: 'Catalogue',
    link: '/catalogue',
    icon: `${process.env.PUBLIC_URL}/assets/icons/outline/task.svg`,
  },
  {
    id: 4,
    name: 'E-Procurement',
    link: '/procurement',
    icon: `${process.env.PUBLIC_URL}/assets/icons/outline/bill.svg`,
  },
  {
    id: 5,
    name: 'Electronic Management',
    link: '/electronic-management',
    icon: `${process.env.PUBLIC_URL}/assets/icons/outline/folder-open.svg`,
  },
  {
    id: 6,
    name: 'Notification',
    link: '/notification',
    icon: `${process.env.PUBLIC_URL}/assets/icons/outline/notification.svg`,
  },
  {
    id: 7,
    name: 'Support',
    link: '/support',
    icon: `${process.env.PUBLIC_URL}/assets/icons/outline/message-question.svg`,
  },
  {
    id: 8,
    name: 'Settings',
    link: '/workflow',
    icon: `${process.env.PUBLIC_URL}/assets/icons/outline/setting.svg`,
  },
];
