import React from 'react';
import './ChooseDemo.css';

const ChooseDemo: React.FC = () => {
  return (
    <div className="choose-demo-container">
      <div>
        <div className="mb-10 px-4 py-2">
          <h1 className="text-2xl">Choose Demo</h1>
          <p className=" text-[12px]">Select a module</p>
        </div>

        <div className=" flex flex-col sm:flex-col md:flex-row lg:flex-row  choose-demo-container  ">
          <div className="choose-demo-card">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/chooseDemo/supplier-icon.svg`}
              alt="Supplier Icon"
            />
            <div className="flex flex-col justify-start items-start">
              <p>Case Scenario</p>
              <h2 className=" ">
                I want to demo
                <br /> as a Supplier.
              </h2>
            </div>
          </div>

          <div className="choose-demo-card">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/chooseDemo/operator-icon.svg`}
              alt="Operator Icon"
            />
            <div className="flex flex-col justify-start items-start">
              <p>Case Scenario</p>
              <h2>
                I want to demo <br /> as an Operator.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseDemo;
