import React, { useRef, RefObject, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AppButton from '../../../shared/components/AppButton/AppButton';
import { useGlobalContext } from '../../../core/context/GlobalContext';
import {
  createSupplier,
  getSupplierById,
} from '../../../services/supplierService';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
// import { ClipLoader } from 'react-spinners';

export interface CompanyRegisterFormValues {
  registrationMethod?: string;
  country?: string;
  registrationNumber?: string;
  dateOfEstablishment?: string;
  businessName?: string;
  businessType?: string;
  streetName?: string;
  officeAddress1?: string;
  officeAddress2?: string;
  municipality?: string;
  city?: string;
  suburb?: string;
  phoneNumber?: string;
  website?: string;
  email?: string;
  supplierCategory?: string;
  commercialRegCertFile?: File | null;
  taxIdentificationFile?: File | null;
  identificationFile?: File | null;
  affiliates?: string;
  facebookLink?: string;
  twitterLink?: string;
  linkedinLink?: string;
  instagramLink?: string;
  companyId?: string;
  typeOfBusiness?: string;
  region?: string;
}

const CompanyRegister: React.FC = () => {
  const { isOpen, setIsOpen } = useGlobalContext();
  const { supplierId, setSupplierId } = useGlobalContext();
  // const [isLoading, setIsLoading] = React.useState(false);
  // const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const isReadOnly = location.state?.isReadOnly;

  const commercialInputRef = useRef<HTMLInputElement>(null);
  const tinInputRef = useRef<HTMLInputElement>(null);
  const photoInputRef = useRef<HTMLInputElement>(null);

  // Function to trigger the file input click
  const handleFileUploadClick = (inputRef: RefObject<HTMLInputElement>) => {
    if (inputRef.current) {
      inputRef.current.dispatchEvent(
        new MouseEvent('click', { bubbles: true, cancelable: true })
      );
    }
  };

  const formik = useFormik<CompanyRegisterFormValues>({
    initialValues: {
      registrationMethod: '',
      country: '',
      registrationNumber: '',
      dateOfEstablishment: '',
      businessName: '',
      businessType: '',
      officeAddress1: '',
      officeAddress2: '',
      municipality: '',
      city: '',
      suburb: '',
      phoneNumber: '',
      website: '',
      email: '',
      supplierCategory: '',
      commercialRegCertFile: null,
      taxIdentificationFile: null,
      identificationFile: null,
      affiliates: '',
      facebookLink: '',
      twitterLink: '',
      linkedinLink: '',
      instagramLink: '',
    },
    validationSchema: Yup.object({
      registrationMethod: Yup.string().required('Required'),
      country: Yup.string().required('Required'),
      registrationNumber: Yup.string().required('Required'),
      businessName: Yup.string().required('Required'),
      dateOfEstablishment: Yup.string().required('Required'),
      officeAddress1: Yup.string().required('Required'),
      municipality: Yup.string().required('Required'),
      city: Yup.string().required('Required'),
      phoneNumber: Yup.string().required('Required'),
      website: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email').required('Required'),
    }),
    onSubmit: async (values: CompanyRegisterFormValues) => {
      try {
        const payload = {
          // companyId: values.commercialRegNo,
          businessName: values.businessName,
          establishmentDate: values.dateOfEstablishment
            ? new Date(values.dateOfEstablishment).toISOString()
            : new Date().toISOString(),
          streetName: values.officeAddress1,
          region: values.municipality,
          city: values.city,
          registrationNumber: values.registrationNumber,
          activate: true,
          town: values.suburb,
          phoneNumber: values.phoneNumber,
          website: values.website,
          email: values.email,
          supplierCategory: 'supplier',
          // supplierCategory: values.supplierCategory,
          // shareholders: [],
          // personalInformations: [],
          // awards: [],
          // companyDocuments: [],
          // industries: [],
        };

        const formData = new FormData();
        Object.keys(payload).forEach((key) => {
          if (payload[key as keyof typeof payload] !== undefined) {
            formData.append(
              key as string,
              payload[key as keyof typeof payload] as string | Blob
            );
          }
        });
        if (values.commercialRegCertFile) {
          formData.append(
            'commercialRegCertFile',
            values.commercialRegCertFile
          );
        }
        if (values.taxIdentificationFile) {
          formData.append(
            'taxIdentificationFile',
            values.taxIdentificationFile
          );
        }
        if (values.identificationFile) {
          formData.append('identificationFile', values.identificationFile);
        }

        const response = await createSupplier(formData);
        if (response.isSuccess) {
          setSupplierId(response?.data?.id);
          toast.success(response?.data?.message);
          getSupplierInformation();
        }
      } catch {
        toast.error('Error creating supplier');
      } finally {
        // setIsLoading(false);
      }
    },
  });

  const getSupplierInformation = async () => {
    try {
      const defaultSupplierId = supplierId
        ? supplierId
        : 'CFABE5F9-8DD4-41C6-1D5F-08DCE17A7B46';
      const response = await getSupplierById(defaultSupplierId);
      if (response) {
        setSupplierId(response?.companyId);
        const supplierData = response;
        formik.setValues({
          businessName: supplierData.businessName,
          dateOfEstablishment: supplierData.dateOfEstablishment
            ? new Date(supplierData.dateOfEstablishment).toISOString().split('T')[0]
            : '',
          typeOfBusiness: supplierData.typeOfBusiness,
          streetName: supplierData.streetName,
          officeAddress1: supplierData.streetName,
          officeAddress2: supplierData.officeAddress2,
          region: supplierData.region,
          municipality: supplierData.region,
          city: supplierData.city,
          suburb: supplierData.town,
          phoneNumber: supplierData.phoneNumber,
          website: supplierData.website,
          email: supplierData.email,
          registrationNumber: supplierData.registrationNumber,
        });
      }
    } catch (error) {
      toast.error('Error getting supplier information');
    }
  };

  useEffect(() => {
    getSupplierInformation();
  }, []);

  return (
    <div className="formCard  bg-white shadow-md p-6 rounded-lg">
      <h1 className="text-2xl flex justify-start font-bold text-center ml-0 m-6">
        Fill out your company’s information
      </h1>
      <div className="divider my-10 mb-4"></div>

      <form
        onSubmit={(e) => e.preventDefault()}
        className="flex flex-col gap-7"
      >
        <div className="flex gap-4 ">
          <div className="gap-10 w-[33%]">
            <label
              className=" text-lg  font-medium  text-gray-500"
              htmlFor="registrationMethod"
            >
              How do you want to register?
              <span className="text-red-500">*</span>
            </label>
            <select
              id="registrationMethod"
              name="registrationMethod"
              onChange={formik.handleChange}
              disabled={isReadOnly}
              value={formik.values.registrationMethod}
              className="w-full border border-gray-300 h-[3.5rem] rounded-md focus:outline-none focus:border-gray-700"
            >
              <option value="">Select</option>
              <option value="online">Online</option>
              <option value="manual">Manual</option>
            </select>
            {formik.touched.registrationMethod &&
              formik.errors.registrationMethod && (
                <span className=" text-red-500 error">
                  {formik.errors.registrationMethod}
                </span>
              )}
          </div>
          <div className=" gap-10 w-[33%]">
            <label
              className=" text-lg font-medium text-gray-500 font-500"
              htmlFor="country"
            >
              Country <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className="h-[3.5rem]"
              id="country"
              name="country"
              onChange={formik.handleChange}
              disabled={isReadOnly}
              value={formik.values.country}
            />
            {formik.touched.country && formik.errors.country && (
              <span className=" text-red-500 error">
                {formik.errors.country}
              </span>
            )}
          </div>
          <div className=" w-[33%]">
            <label
              className=" text-lg  font-medium text-gray-500 font-500"
              htmlFor="registrationNumber"
            >
              Commercial Reg. No<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className="h-[3.5rem]"
              id="registrationNumber"
              name="registrationNumber"
              onChange={formik.handleChange}
              disabled={isReadOnly}
              value={formik.values.registrationNumber}
            />
            {formik.touched.registrationNumber &&
              formik.errors.registrationNumber && (
                <span className=" text-red-500 error">
                  {formik.errors.registrationNumber}
                </span>
              )}
          </div>{' '}
        </div>

        <div className=" flex gap-4">
          <div className="form-grou w-[33%]">
            <label
              className=" text-lg  font-medium text-gray-500 font-500"
              htmlFor="dateOfEstablishment"
            >
              Date of Establishment<span className="text-red-500">*</span>
            </label>
            <input
              type="date"
              className="h-[3.5rem]"
              id="dateOfEstablishment"
              name="dateOfEstablishment"
              disabled={isReadOnly}
              onChange={formik.handleChange}
              value={formik.values.dateOfEstablishment}
            />
            {formik.touched.dateOfEstablishment &&
              formik.errors.dateOfEstablishment && (
                <span className=" text-red-500 error">
                  {formik.errors.dateOfEstablishment}
                </span>
              )}
          </div>

          <div className="form-group w-[33%]">
            <label
              className=" text-lg  font-medium text-gray-500 font-500"
              htmlFor="businessName"
            >
              Business Name<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className="h-[3.5rem]"
              id="businessName"
              name="businessName"
              disabled={isReadOnly}
              onChange={formik.handleChange}
              value={formik.values.businessName}
            />
            {formik.touched.businessName && formik.errors.businessName && (
              <span className=" text-red-500 error">
                {formik.errors.businessName}
              </span>
            )}
          </div>

          <div className="form-group w-[33%]">
            <label
              className=" text-lg  font-medium text-gray-500 font-500"
              htmlFor="businessType"
            >
              Business Type<span className="text-red-500">*</span>
            </label>
            <select
              id="businessType"
              name="businessType"
              onChange={formik.handleChange}
              value={formik.values.businessType}
              className="w-full border border-gray-300 h-[3.5rem] rounded-md focus:outline-none focus:border-gray-700"
            >
              <option value="">Select</option>
              <option value="sole-proprietorship">Sole Proprietorship</option>
              <option value="partnership">Partnership</option>
              <option value="corporation">Corporation</option>
            </select>
            {formik.touched.businessType && formik.errors.businessType && (
              <span className=" text-red-500 error">
                {formik.errors.businessType}
              </span>
            )}
          </div>
        </div>
        <div className="divider my-10 mb-4"></div>
        <div>
          {/* Office Address */}
          <div className=" flex gap-4">
            <div className="form-group w-[50%]">
              <label
                className=" text-lg  font-medium text-gray-500 font-500"
                htmlFor="officeAddress1"
              >
                Office Address Line 1<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className="h-[3.5rem]"
                id="officeAddress1"
                name="officeAddress1"
                onChange={formik.handleChange}
                value={formik.values.officeAddress1}
              />
              {formik.touched.officeAddress1 &&
                formik.errors.officeAddress1 && (
                  <span className=" text-red-500 error">
                    {formik.errors.officeAddress1}
                  </span>
                )}
            </div>

            <div className="form-group w-[50%]">
              <label
                className=" text-lg  font-medium text-gray-500 font-500"
                htmlFor="officeAddress2"
              >
                Office Address Line 2
              </label>
              <input
                type="text"
                className="h-[3.5rem]"
                id="officeAddress2"
                name="officeAddress2"
                onChange={formik.handleChange}
                value={formik.values.officeAddress2}
              />
            </div>
          </div>
        </div>

        {/* Municipality and City */}
        <div className=" flex gap-4">
          <div className="form-group w-[33%]">
            <label
              className=" text-lg  font-medium text-gray-500 font-500"
              htmlFor="municipality"
            >
              Municipality/Region<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className="h-[3.5rem]"
              id="municipality"
              name="municipality"
              onChange={formik.handleChange}
              disabled={isReadOnly}
              value={formik.values.municipality}
            />
            {formik.touched.municipality && formik.errors.municipality && (
              <span className=" text-red-500 error">
                {formik.errors.municipality}
              </span>
            )}
          </div>

          <div className="form-group w-[33%]">
            <label
              className=" text-lg  font-medium text-gray-500 font-500"
              htmlFor="city"
            >
              Post Administrative/City<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className="h-[3.5rem]"
              id="city"
              name="city"
              onChange={formik.handleChange}
              value={formik.values.city}
            />
            {formik.touched.city && formik.errors.city && (
              <span className=" text-red-500 error">{formik.errors.city}</span>
            )}
          </div>
          <div className="form-group w-[33%]">
            <label
              className=" text-lg  font-medium text-gray-500 font-500"
              htmlFor="city"
            >
              Suburb or Town<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className="h-[3.5rem]"
              id="suburb"
              name="suburb"
              onChange={formik.handleChange}
              disabled={isReadOnly}
              value={formik.values.suburb}
            />
            {formik.touched.suburb && formik.errors.suburb && (
              <span className=" text-red-500 error">
                {formik.errors.suburb}
              </span>
            )}
          </div>
        </div>
        <div className=" flex gap-4">
          <div className="form-group w-[33%]">
            <label
              className=" text-lg  font-medium text-gray-500 font-500"
              htmlFor="phoneNumber"
            >
              PhoneNumber<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className="h-[3.5rem]"
              id="phoneNumber"
              name="phoneNumber"
              onChange={formik.handleChange}
              value={formik.values.phoneNumber}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber && (
              <span className=" text-red-500 error">
                {formik.errors.phoneNumber}
              </span>
            )}
          </div>

          <div className="form-group w-[33%]">
            <label
              className=" text-lg  font-medium text-gray-500 font-500"
              htmlFor="website"
            >
              Website<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className="h-[3.5rem]"
              id="website"
              name="website"
              onChange={formik.handleChange}
              value={formik.values.website}
            />
            {formik.touched.website && formik.errors.website && (
              <span className=" text-red-500 error">
                {formik.errors.website}
              </span>
            )}
          </div>
          <div className="form-group w-[33%]">
            <label
              className=" text-lg  font-medium text-gray-500 font-500"
              htmlFor="email"
            >
              Email<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className="h-[3.5rem]"
              id="email"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email && (
              <span className=" text-red-500 error">{formik.errors.email}</span>
            )}
          </div>
        </div>
        <div className="divider my-10 mb-4"></div>

        {/* File Upload for Verification */}
        <div className=" flex flex-col gap-10">
          <div className="verification-upload ">
            <label
              className=" text-lg  font-medium text-gray-500 font-500"
              htmlFor="commercialRegCertFile"
            >
              Commercial Registration Certificate
            </label>
            <div className="flex items-center gap-2">
              <input
                className="border p-2 rounded-md w-full h-[40px]"
                type="file"
                id="commercialRegCertFile"
                ref={commercialInputRef} // attach ref to input
                // style={{ display: "none" }} // hide the input
                name="commercialRegCertFile"
                onChange={(event) =>
                  event.currentTarget.files &&
                  formik.setFieldValue(
                    'commercialRegCertFile',
                    event.currentTarget.files[0]
                  )
                }
              />
              <button
                type="button"
                onClick={() => handleFileUploadClick(commercialInputRef)}
                className="bg-[#20b841] w-[108px] text-white h-[36px] text-lg flex justify-center items-center  font-semibold rounded-md"
              >
                Upload
              </button>
            </div>
            <span className=" text-[#667085] text-lg">
              Please upload .jepg, .jpg, .png or .pdf, size less than 100KB
            </span>
          </div>
          <div className="verification-upload ">
            <label
              className=" text-lg  font-medium text-gray-500 font-500"
              htmlFor="taxIdentificationFile"
            >
              Tax Identification Number (TIN)
            </label>
            <div className="flex items-center gap-2">
              <input
                className="border p-2 rounded-md w-full h-[40px]"
                type="file"
                ref={tinInputRef}
                id="taxIdentificationFile"
                name="taxIdentificationFile"
                onChange={(event) =>
                  event.currentTarget.files &&
                  formik.setFieldValue(
                    'taxIdentificationFile"',
                    event.currentTarget.files[0]
                  )
                }
              />
              <button
                type="button"
                onClick={() => handleFileUploadClick(tinInputRef)}
                className="bg-[#20b841] w-[108px] text-white h-[36px] text-lg flex justify-center items-center  font-semibold rounded-md"
              >
                Upload
              </button>
            </div>
            <span className=" text-[#667085] text-lg">
              Please upload .jepg, .jpg, .png or .pdf, size less than 100KB
            </span>
          </div>
          <div className="verification-upload ">
            <label
              className=" text-lg  font-medium text-gray-500 font-500"
              htmlFor="Photo of Identification "
            >
              Photo of Identification ID (Citizen ID, Passport or Electoral ID)
            </label>
            <div className="flex items-center gap-2">
              <input
                className="border p-2 rounded-md w-full h-[40px]"
                type="file"
                ref={photoInputRef}
                id="identificationFile"
                name="identificationFile"
                onChange={(event) =>
                  event.currentTarget.files &&
                  formik.setFieldValue(
                    'identificationFile',
                    event.currentTarget.files[0]
                  )
                }
              />
              <button
                type="button"
                onClick={() => handleFileUploadClick(photoInputRef)}
                className="bg-[#20b841] w-[108px] text-white h-[36px] text-lg flex justify-center items-center  font-semibold rounded-md"
              >
                Upload
              </button>
            </div>
            <span className=" text-[#667085] text-lg">
              Please upload .jepg, .jpg, .png or .pdf, size less than 100KB
            </span>
          </div>
        </div>
        <div className="divider my-10 mb-4"></div>
        {/* <div className="flex flex-col gap-4">
          {' '}
          <div className="flex  justify-between w-full space-x-4">
            <h1 className=" text-[1.4rem] font-medium">
              Affliates and Subsidiaries
            </h1>
            <button
              type="button"
              className="text-lg font-semibold bg-[#FFC726]  rounded-lg w-[92px] h-[36px]"
              onClick={() => setIsOpen(true)}
              disabled={!supplierId}
            >
              +Add
            </button>
          </div>

          <textarea
            name="affiliates"
            value={formik.values.affiliates}
            onChange={formik.handleChange}
            placeholder="Type your comment or address..."
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
            rows={4}
          />
        </div> */}
        <div className="flex flex-col gap-10">
          <div className="mt-4">
            <h1 className="text-[1.4rem] font-medium ">
              Social Media (Optional)
            </h1>
          </div>

          <div className=" flex gap-4">
            <div className="form-group w-[50%]">
              <input
                placeholder="Enter your facebook link"
                type="text"
                className="h-[3.5rem]"
                id="facebookLink"
                name="facebookLink"
                onChange={formik.handleChange}
                value={formik.values.facebookLink}
              />
              {formik.touched.facebookLink && formik.errors.facebookLink && (
                <span className=" text-red-500 error">
                  {formik.errors.facebookLink}
                </span>
              )}
            </div>

            <div className="form-group w-[50%]">
              <input
                placeholder="Enter your twitter link"
                type="text"
                className="h-[3.5rem]"
                id="twitterLink"
                name="twitterLink"
                onChange={formik.handleChange}
                value={formik.values.twitterLink}
              />
            </div>
          </div>

          <div className=" flex gap-4">
            <div className="form-group w-[50%]">
              <input
                placeholder="Enter your Linkedin link"
                type="text"
                className="h-[3.5rem]"
                id="linkedinLink"
                name="linkedinLink"
                onChange={formik.handleChange}
                value={formik.values.linkedinLink}
              />
              {formik.touched.linkedinLink && formik.errors.linkedinLink && (
                <span className=" text-red-500 error">
                  {formik.errors.linkedinLink}
                </span>
              )}
            </div>

            <div className="form-group w-[50%]">
              <input
                placeholder="Enter your Instagram link"
                type="text"
                className="h-[3.5rem]"
                id="instagramLink"
                name="instagramLink"
                onChange={formik.handleChange}
                value={formik.values.instagramLink}
              />
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div className="button-container">
          <AppButton
            title="Submit"
            className="text-lg font-semibold bg-[#FFC726]  rounded-lg w-[92px] h-[36px]"
            onClick={() => formik.submitForm()}
          />
        </div>
      </form>
    </div>
  );
};

export default CompanyRegister;
