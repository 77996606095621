import React, { useState } from 'react';
import { CiFilter } from 'react-icons/ci';
import { FaSort } from 'react-icons/fa'; // Sort icon
import { BsThreeDotsVertical } from 'react-icons/bs'; // 3-dots icon
import Navbar from '../../shared/layouts/NavBar/Navbar';
import Sidebar from '../../shared/layouts/SideBar/Sidebar';

type SupplierApplicationItem = {
  id: number;
  stepName: string;
  description: string;
  commandQuery: string;
  reviewPeriods: number;
};

const supplierApplicationData: SupplierApplicationItem[] = [
  {
    id: 1,
    stepName: 'Initial Review',
    description: 'First step of review',
    commandQuery: 'SELECT * FROM steps',
    reviewPeriods: 48,
  },
  {
    id: 2,
    stepName: 'Final Approval',
    description: 'Final step before approval',
    commandQuery: 'SELECT * FROM approvals',
    reviewPeriods: 24,
  },
  // Add more data as needed
];

const SupplierApplicationWorkFlow: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(2); // Items per page for pagination
  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState<boolean[]>(
    supplierApplicationData.map(() => false)
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(
    null
  );

  const toggleSelectAll = () => {
    const newSelected = !selectedAll;
    setSelectedAll(newSelected);
    setSelectedItems(supplierApplicationData.map(() => newSelected));
  };

  const toggleCheckbox = (index: number) => {
    const updatedSelectedItems = [...selectedItems];
    updatedSelectedItems[index] = !updatedSelectedItems[index];
    setSelectedItems(updatedSelectedItems);
  };

  const toggleDropdown = (index: number) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = supplierApplicationData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(supplierApplicationData.length / itemsPerPage);

  return (
    <>
      <Navbar />
      <div className="overview-container">
        <Sidebar />
        <div className="p-4 flex-1">
          {/* Path and Title */}
          <div className="mb-4">
            <h1 className="text-3xl">Supplier Application - Workflow</h1>
          </div>

          {/* Card-like Container */}
          <div className="bg-white flex gap-10  p-4 rounded-lg shadow-md mb-6">
            <div className="mb-4">
              <div className="flex flex-col">
                <strong>Application Type:</strong>
                <span>Supplier Application</span>
              </div>
            </div>
            <div className="mb-4">
              <strong>Description:</strong>
              <p>A supplier workflow to streamline application approvals.</p>
            </div>
            <div className="mb-4">
              <strong>Date Created:</strong>
              <p>{new Date().toLocaleDateString()}</p>
            </div>
          </div>

          {/* Add, Filter, Search */}
          <div className="flex justify-between mb-4">
            <div className="flex items-center">
              <input
                type="text"
                placeholder="Search"
                className="border p-2 rounded "
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button className="p-2">
                <CiFilter size={24} />
              </button>
            </div>
            <button className="p-2 border border-gray-400">+ Add</button>
          </div>

          {/* Table */}
          <table className="min-w-full table-auto border-collapse border border-gray-300">
            <thead className="bg-gray-200">
              <tr>
                <th className="border">
                  <input
                    type="checkbox"
                    checked={selectedAll}
                    onChange={toggleSelectAll}
                    className="form-checkbox text-yellow-500 focus:ring-yellow-400"
                  />
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left">
                  ID
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left">
                  Step Name
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left">
                  Description
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left">
                  Command Query
                  <FaSort className="inline ml-1 cursor-pointer" />
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left">
                  Review Periods (hrs)
                  <FaSort className="inline ml-1 cursor-pointer" />
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={index}>
                  <td className="border px-4 py-2">
                    <input
                      type="checkbox"
                      checked={selectedItems[index]}
                      onChange={() => toggleCheckbox(index)}
                      className="form-checkbox text-yellow-500 focus:ring-yellow-400"
                    />
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {item.id}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {item.stepName}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {item.description}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {item.commandQuery}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {item.reviewPeriods}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 relative">
                    <button
                      onClick={() => toggleDropdown(index)}
                      className="text-gray-600"
                    >
                      <BsThreeDotsVertical />
                    </button>
                    {openDropdownIndex === index && (
                      <div className="absolute right-0 mt-2 w-40 bg-white border border-gray-300 rounded shadow-lg z-10">
                        <ul>
                          <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                            View
                          </li>
                          <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                            Edit
                          </li>
                          <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-red-500">
                            Delete
                          </li>
                        </ul>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="flex justify-between items-center mt-4">
            <button
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
              className="p-2 border border-gray-400"
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
              className="p-2 border border-gray-400"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupplierApplicationWorkFlow;
