const baseUrl = process.env.REACT_APP_BASE_URL;
export const Endpoint = {
  account: {
    login: `${baseUrl}/account/login`,
    register: `${baseUrl}/account/register`,
    confirmEmail: `${baseUrl}/account/confirmEmail`,
    resendOtp: `${baseUrl}/account/resend/otp`,
    googleAuth: `${baseUrl}/account/google`,
    externalOAuth: `${baseUrl}/account/externallogin?provider=`,
  },
  suppliers: {
    getAll: `${baseUrl}/suppliers`,
    getSubsidiaries: `${baseUrl}/supplier/subsidiaries`,
    getOne: (id: string) => `${baseUrl}/supplier/suppliers?supplierId=${id}`,
    create: `${baseUrl}/supplier/create`,
    update: `${baseUrl}/supplier/edit`,
    delete: `${baseUrl}/supplier/remove`,
    contact: {
      create: `${baseUrl}/supplier/contact/create`,
      update: `${baseUrl}/supplier/contact/edit`,
      get: (supplierId: string) => `${baseUrl}/supplier/contacts/${supplierId}`,
      delete: `${baseUrl}/supplier/contact/remove`,
    },
    personnel: {
      create: `${baseUrl}/supplier/personnel/create`,
      update: `${baseUrl}/supplier/personnel/edit`,
      get: (supplierId: string) =>
        `${baseUrl}/supplier/personnel/personnels/${supplierId}`,
      delete: `${baseUrl}/supplier/personnel/remove`,
    },
    industry: {
      create: `${baseUrl}/supplier/industry/create`,
      update: `${baseUrl}/supplier/industry/edit`,
      get: (supplierId: string) =>
        `${baseUrl}/supplier/industries/${supplierId}`,
      delete: `${baseUrl}/supplier/industry/delete`,
    },
    shareholder: {
      create: `${baseUrl}/supplier/shareholder/create`,
      update: `${baseUrl}/supplier/shareholder/edit`,
      get: (supplierId: string) =>
        `${baseUrl}/supplier/shareholders/${supplierId}`,
      delete: `${baseUrl}/supplier/shareholder/remove`,
    },
    project: {
      create: `${baseUrl}/supplier/project/create`,
      update: `${baseUrl}/supplier/project/edit`,
      get: `${baseUrl}/supplier/projects`,
      delete: `${baseUrl}/supplier/project/delete`,
    },
    award: {
      create: `${baseUrl}/supplier/award/create`,
      update: `${baseUrl}/supplier/award/edit`,
      get: (supplierId: string) => `${baseUrl}/supplier/awards/${supplierId}`,
      delete: `${baseUrl}/supplier/award/delete`,
    },
  },
  workflow: {
    get: `${baseUrl}/workflow/list`,
    create: `${baseUrl}/workflow/create`,
    update: `${baseUrl}/workflow/edit`,
    delete: `${baseUrl}/workflow/delete`,
    getTypes: `${baseUrl}/workflow/types`,
    command: {
      create: `${baseUrl}/workflow/command/create`,
      update: `${baseUrl}/workflow/command/edit`,
      get: `${baseUrl}/workflow/commands`,
      delete: `${baseUrl}/workflow/command/remove`,
    },
    process: {
      create: `${baseUrl}/workflow/process/create`,
      update: `${baseUrl}/workflow/process/edit`,
      get: `${baseUrl}/workflow/processes`,
      delete: `${baseUrl}/workflow/process/remove`,
    },
    parameter: {
      create: `${baseUrl}/workflow/parameter/create`,
      update: `${baseUrl}/workflow/parameter/edit`,
      get: `${baseUrl}/workflow/parameter/list`,
      delete: `${baseUrl}/workflow/parameter/remove`,
    },
    step: {
      create: `${baseUrl}/workflow/step/create`,
      update: `${baseUrl}/workflow/step/edit`,
      get: `${baseUrl}/workflow/steps`,
      delete: `${baseUrl}/workflow/step/remove`,
    },
  },
};
