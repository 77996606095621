import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup'; // Import Yup for validation
import {
  createWorkflow,
  getWorkflowTypes,
} from '../../services/workflowServices';
import { toast } from 'react-toastify';

interface ModalProps {
  isModalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  onCreateSuccess: () => void;
}

interface WorkflowTypes {
  [key: string]: number;
}

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  typeOfWorkflow: Yup.number()
    .required('Type of Workflow is required')
    .integer('Type of Workflow must be an integer'),
  workflowName: Yup.string()
    .required('Workflow Name is required')
    .min(3, 'Workflow Name should be at least 3 characters long'),
  descriptions: Yup.string()
    .required('Description is required')
    .min(10, 'Description should be at least 10 characters long'),
});

const WorkFlowModal: React.FC<ModalProps> = ({
  isModalOpen,
  setModalOpen,
  onCreateSuccess,
}) => {
  const [workflowTypes, setWorkflowTypes] = useState<WorkflowTypes>({});
  const formik = useFormik({
    initialValues: {
      typeOfWorkflow: 0,
      workflowName: '',
      descriptions: '',
    },
    validationSchema, // Attach validation schema
    onSubmit: async (values) => {
      const typeOfWorkflow = Number(values.typeOfWorkflow);
      try {
        const payload = { ...values, typeOfWorkflow };
        const response: any = await createWorkflow(payload);
        if (response.isSuccess) {
          toast.success(response?.data?.message);
          onCreateSuccess();
        }
      } catch (error: any) {
        toast.error(error);
      }
      setModalOpen(false);
    },
  });

  const getWorkFlowTypes = async () => {
    try {
      const response = await getWorkflowTypes();
      setWorkflowTypes(response);
    } catch (error: any) {
      toast.error(error);
    }
  };

  useEffect(() => {
    getWorkFlowTypes();
  }, []);

  return (
    <>
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-10">
          <div className="bg-white w-full max-w-3xl p-8 rounded-2xl relative">
            <button
              onClick={() => setModalOpen(false)}
              className="absolute top-3 right-3 text-gray-500 text-2xl"
            >
              <FaTimes />
            </button>
            <h1 className="text-2xl font-bold">Workflow Configuration</h1>

            {/* Divider */}
            <div className="divider my-6 !bg-[#E5E5E5]"></div>

            {/* Form handled by Formik */}
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-6">
                <label
                  htmlFor="typeOfWorkflow"
                  className="block text-sm font-medium text-gray-700"
                >
                  Workflow Type
                </label>
                <select
                  id="typeOfWorkflow"
                  name="typeOfWorkflow"
                  onChange={formik.handleChange}
                  value={formik.values.typeOfWorkflow}
                  className="block w-full h-10 text-sm text-gray-700 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Select workflow type</option>
                  {workflowTypes &&
                    Object.entries(workflowTypes).map(([name, value]) => (
                      <option key={value} value={value}>
                        {name}
                      </option>
                    ))}
                </select>
                {formik.errors.typeOfWorkflow &&
                  formik.touched.typeOfWorkflow && (
                    <span className="text-red-500">
                      {formik.errors.typeOfWorkflow}
                    </span>
                  )}
              </div>

              <div className="mb-6">
                <label
                  htmlFor="workflowName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Workflow Name
                </label>
                <input
                  type="text"
                  id="workflowName"
                  name="workflowName"
                  placeholder="Workflow Name"
                  className={`w-full border text-sm border-gray-300 p-2 rounded-lg ${
                    formik.touched.workflowName && formik.errors.workflowName
                      ? 'border-red-500'
                      : ''
                  }`}
                  value={formik.values.workflowName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur} // For blur validation
                />
                {/* Show validation error */}
                {formik.touched.workflowName && formik.errors.workflowName && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.workflowName}
                  </div>
                )}
              </div>

              <div className="mb-6">
                <label
                  htmlFor="descriptions"
                  className="block text-sm font-medium text-gray-700"
                >
                  Description
                </label>
                <textarea
                  id="descriptions"
                  name="descriptions"
                  placeholder="Enter description"
                  className={`w-full border border-gray-300 p-2 rounded-lg ${
                    formik.touched.descriptions && formik.errors.descriptions
                      ? 'border-red-500'
                      : ''
                  }`}
                  rows={4}
                  value={formik.values.descriptions}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {/* Show validation error */}
                {formik.touched.descriptions && formik.errors.descriptions && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.descriptions}
                  </div>
                )}
              </div>

              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setModalOpen(false)}
                  className="bg-gray-300 text-black-700 px-12 h-12 py-2 rounded-lg font-semibold"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="bg-green-500 text-white px-12 h-12 py-2 rounded-lg font-semibold"
                >
                  Publish
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default WorkFlowModal;
