import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useFormik } from 'formik';
import '../Forget-Password/PasswordReset.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import AppButton from '../../../shared/components/AppButton/AppButton';
import { PasswordResetValidationSchema } from '../ValidationSchema';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../../shared/layouts/NavBar/Navbar';

const ResetPasswordCard: React.FC = () => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: PasswordResetValidationSchema,
    onSubmit: () => {
      navigate('/login');
    },
  });

  return (
    <div>
      <Navbar />
      <Modal
        isOpen={true}
        style={{
          content: {
            width: '700px',
            height: '400px',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '10px',
            overflow: 'hidden',
            border: 'none',
          },
        }}
      >
        <center className="reset-password-card flex flex-col py-10">
          <h2 className="flex justify-start">Reset Password</h2>

          <form onSubmit={formik.handleSubmit}>
            <div className="flex justify-start">
              <label htmlFor="newPassword">
                {t('newPassword', 'New Password')}*
              </label>
            </div>
            <div className="password-input-wrapper">
              <input
                type={showPassword ? 'text' : 'password'}
                id="newPassword"
                name="newPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.newPassword}
                placeholder={t('enterNewPassword', 'Enter New Password')}
              />
              <button
                type="button"
                className="password-toggle-btn"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </button>
              {formik.touched.newPassword && formik.errors.newPassword && (
                <span className="text-red-500 flex justify-start">
                  {formik.errors.newPassword}
                </span>
              )}
            </div>

            <div className="flex justify-start mt-4">
              <label htmlFor="confirmPassword">
                {t('confirmNewPassword', 'Confirm New Password')}*
              </label>
            </div>
            <div className="password-input-wrapper">
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                id="confirmPassword"
                name="confirmPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                placeholder={t('enterConfirmPassword', 'Confirm New Password')}
              />
              <button
                type="button"
                className="password-toggle-btn"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
              </button>
              {formik.touched.confirmPassword &&
                formik.errors.confirmPassword && (
                  <span className="text-red-500 flex justify-start">
                    {formik.errors.confirmPassword}
                  </span>
                )}
            </div>

            <AppButton
              className="w-full bg-[#FFC726] text-black font-semibold p-2 rounded-md"
              title={t('login', 'Login')}
              onClick={() => formik.handleSubmit()}
            />
          </form>
        </center>
      </Modal>
    </div>
  );
};

export default ResetPasswordCard;
