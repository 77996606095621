import React, { useEffect, useState } from 'react';
import { CiFilter } from 'react-icons/ci';
import { FaSort } from 'react-icons/fa'; // Sort icon
import { BsThreeDotsVertical } from 'react-icons/bs'; // 3-dots icon
import ReactPaginate from 'react-paginate';
import Navbar from '../../shared/layouts/NavBar/Navbar';
import Sidebar from '../../shared/layouts/SideBar/Sidebar';
import CommandModal from './CommandModal'; // Importing the new modal
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteCommand, getCommands } from '../../services/workflowServices';
import Delete from '../../shared/utils/Delete/Delete';

interface PageRequest {
  pageNumber: number;
  pageSize: number;
  workflowId: string;
}
export interface CommandData {
  descriptions: string;
  queryCommand: string;
  className: string;
  methodName: string;
  commandType: string;
  actionType: string;
  workflowId: string;
  workflowCommandId: string;
}

const CommandConfig: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 3;
  const [selectedAll, setSelectedAll] = useState(false);
  const [commandData, setCommandData] = useState<CommandData[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [commandItem, setCommandItem] = useState<CommandData | null>(null);
  const [selectedItems, setSelectedItems] = useState<boolean[]>(
    commandData.map(() => false)
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(
    null
  );
  const [isModalOpen, setModalOpen] = useState(false); // Modal state

  const location = useLocation();
  const workflowId = location.state?.workflowId;
  const pageRequest: PageRequest = {
    pageNumber: 1,
    pageSize: 10,
    workflowId: workflowId,
  };

  const toggleSelectAll = () => {
    const newSelected = !selectedAll;
    setSelectedAll(newSelected);
    setSelectedItems(commandData.map(() => newSelected));
  };

  const toggleCheckbox = (index: number) => {
    const updatedSelectedItems = [...selectedItems];
    updatedSelectedItems[index] = !updatedSelectedItems[index];
    setSelectedItems(updatedSelectedItems);
  };

  const toggleDropdown = (index: number) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };

  const handlePageClick = (event: { selected: number }) => {
    setCurrentPage(event.selected);
  };

  const editModal = (command: CommandData) => {
    setModalOpen(true);
    setCommandItem(command);
  };
  const deleteModal = (command: CommandData) => {
    setCommandItem(command);
    setIsDeleteModalOpen(true);
  };

  const deleteSelectedCommand = async () => {
    if (!commandItem) return;
    const { workflowCommandId } = commandItem;
    const response: any = await deleteCommand(workflowCommandId);
    if (response.isSuccess) {
      toast.success('Command deleted successfully');
      setIsDeleteModalOpen(false);
      fetchCommandList();
    }
  };

  const closeEditModal = () => {
    setModalOpen(false);
    setCommandItem(null);
    fetchCommandList();
  };

  const fetchCommandList = async () => {
    try {
      const response = await getCommands(pageRequest);
      if (response) {
        setCommandData(response.data);
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  useEffect(() => {
    fetchCommandList();
  }, []);

  const filteredData = commandData.filter((item) =>
    item.descriptions.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const offset = currentPage * itemsPerPage;
  const currentItems = filteredData.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <>
      <div className="p-4 flex-1">
          {/* Title and Add button */}
          <div className="flex justify-between my-4">
            <h1 className="text-3xl mb-4">Workflow Commands</h1>
            <button
              className="text-black px-4 py-2 border border-[#101828] text-[#101828] font-semibold rounded-lg"
              onClick={() => setModalOpen(true)} // Open the modal
            >
              + New Command
            </button>
          </div>

          {/* Search & Filter */}
          <div className="flex justify-end mb-4">
            <div className="flex justify-end items-center">
              <button className="p-2">
                <CiFilter size={24} />
              </button>
              <div className="flex justify-end items-center relative">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/outline/search.svg`}
                  alt="search"
                  className="absolute left-[5px]"
                />
                <input
                  type="text"
                  placeholder="Search"
                  className="border p-2 rounded-lg indent-8"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
          </div>

          {/* Table */}
          <table className="min-w-full table-auto border-collapse border border-gray-300">
            <thead className="bg-gray-200">
              <tr>
                {/* <th className="border">
                  <input
                    type="checkbox"
                    checked={selectedAll}
                    onChange={toggleSelectAll}
                    className="form-checkbox text-yellow-500 focus:ring-yellow-400"
                  />
                </th> */}
                <th className="border border-gray-300 px-4 py-2 text-left">
                  ID
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left">
                  Description
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left">
                  ClassName
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left">
                  CommandType
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left">
                  Command Query
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left">
                  ActionType
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((command, index) => (
                <tr key={index}>
                  {/* <td className="border px-4 py-2">
                    <input
                      type="checkbox"
                      checked={selectedItems[index]}
                      onChange={() => toggleCheckbox(index)}
                      className="form-checkbox text-yellow-500 focus:ring-yellow-400"
                    />
                  </td> */}
                  <td className="border border-gray-300 px-4 py-2">
                    {command.workflowCommandId}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {command.descriptions}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {command.className}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {command.commandType}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {command.queryCommand}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {command.actionType}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 relative">
                    {/* 3-dots icon for dropdown */}
                    <button
                      onClick={() => toggleDropdown(index)}
                      className="text-gray-600"
                    >
                      <BsThreeDotsVertical />
                    </button>
                    {/* Dropdown menu */}
                    {openDropdownIndex === index && (
                      <div className="absolute right-0 mt-2 w-40 bg-white border border-gray-300 rounded shadow-lg z-10">
                        <ul>
                          {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                            View
                          </li> */}
                          <li
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => editModal(command)}
                          >
                            Edit
                          </li>
                          <li
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-red-500"
                            onClick={() => deleteModal(command)}
                          >
                            Delete
                          </li>
                        </ul>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="flex justify-center items-center mt-4">
            <ReactPaginate
              previousLabel={'< Previous'}
              nextLabel={'Next >'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={'pagination items-center flex space-x-2'}
              activeClassName={'bg-black rounded text-white'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              disabledClassName={'disabled'}
              pageClassName={
                'page-item px-3 py-1 border rounded hover:bg-gray-200'
              }
              pageLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              nextLinkClassName={'page-link'}
              activeLinkClassName={'active-link'}
            />
          </div>

          {/* Modal for adding new command */}
          {isModalOpen && (
            <CommandModal
              isOpen={isModalOpen}
              onClose={() => setModalOpen(false)}
              onEdit={closeEditModal}
              workflowId={workflowId}
              initialValues={commandItem}
            />
          )}

          {isDeleteModalOpen && (
            <Delete
              title="Are you sure"
              desc="This action is permanent, all data would be lost."
              onDelete={deleteSelectedCommand}
              onCancel={() => setModalOpen(false)}
            />
          )}
        </div>
    </>
  );
};

export default CommandConfig;
