import React from 'react';
import Sidebar from '../../shared/layouts/SideBar/Sidebar';
import ProgressBar from '../../shared/components/ProgressBar/ProgressBar';
import './SupplierOverview.css'; // Add custom styles
import { useTranslation } from 'react-i18next';

const SupplierOverview = () => {
  const { t } = useTranslation();
  const cards = [
    { id: 1, number: 10, text: `${t('new_supplier', 'New Suppliers')}` },
    {
      id: 2,
      number: 50,
      text: `${t('verified_suppliers', 'Verified Suppliers')}`,
    },
    {
      id: 3,
      number: 25,
      text: `${t('pending_verification', 'Pending Verification')}`,
    },
    {
      id: 4,
      number: 5,
      text: `${t('rejected_suppliers', 'Rejected Suppliers')}`,
    },
  ];

  return (
    <div className="view-area">
        <div className=" mb-6">
          <h1 className=" font-semibold text-[24px]">
            {t('overview', 'Overview')}
          </h1>
          <p className="">
            {t('view_team_activities', 'View your team’s activities.')}
          </p>
        </div>

        <div className="cards-container">
          {cards.map((card) => (
            <div key={card.id} className="card flex">
              <div className=" ">
                {' '}
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/chooseDemo/supplier-icon.svg`}
                  alt="Supplier Icon"
                />
              </div>
              <div className="card-info">
                <p className="card-number">{card.number}</p>
                <p className="text-[12px]">{card.text}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="progress-section">
          <ProgressBar />
        </div>
      </div>
  );
};

export default SupplierOverview;
