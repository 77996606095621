import { Endpoint } from '../endpoint/endpoint';
import axiosInstance from '../axiosInstance';

interface RegisterData {
  userName: string;
  password: string;
  confirmPassword: string;
  email: string;
  phoneNumber: string;
}
interface LoginData {
  userName: string;
  password: string;
}
export interface OtpData {
  code: string;
  email: string;
}
export const Register = async (userData: RegisterData) => {
  const url = Endpoint.account.register;

  try {
    const response = await axiosInstance.post(url, userData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const Login = async (loginData: LoginData) => {
  const url = Endpoint.account.login;

  try {
    const response = await axiosInstance.post(url, loginData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return response.data;
  } catch {
    // console.log(error)
  }
};

export const ConfirmEmail = async (otpData: OtpData) => {
  const url = Endpoint.account.confirmEmail;

  try {
    const response = await axiosInstance.post(url, otpData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return response.data;
  } catch {
    // console.log(error)
  }
};

export const ResendOtp = async (otpData: { email: string }) => {
  const url = Endpoint.account.resendOtp;

  try {
    const response = await axiosInstance.post(url, otpData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return response.data;
  } catch {
    // console.log(error)
  }
};

export const GoogleAuth = async (tokenData: { token: string }) => {
  const url = Endpoint.account.googleAuth;
  try {
    const response = await axiosInstance.post(url, tokenData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return response.data;
  } catch {
    // console.log(error)
  }
};

export const ExternalOAuth = async (provider: string) => {
  const url = Endpoint.account.externalOAuth + provider;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    // console.log(error)
  }
};
