import React, { useEffect, useState } from 'react';
import { CiFilter } from 'react-icons/ci';
import { FaSort } from 'react-icons/fa'; // Importing sort icon
import { BsThreeDotsVertical } from 'react-icons/bs'; // Importing 3-dots icon
import Navbar from '../../shared/layouts/NavBar/Navbar';
import Sidebar from '../../shared/layouts/SideBar/Sidebar';
import WorkFlowModal from './WorkFlowModal';
import { getWorkflows } from '../../services/workflowServices';
import { workflowTypeToString } from '../../services/utilServices';
import { useNavigate } from 'react-router-dom';

interface PageRequest {
  pageNumber: number;
  pageSize: number;
}

type WorkflowItem = {
  workflowId: string;
  typeOfWorkflow: number;
  numberOfStages: number;
  status: string;
  createdDate: string;
};

const WorkflowConfiguration: React.FC = () => {
  const [selectedAll, setSelectedAll] = useState(false);
  const [workflowData, setWorkflowData] = useState<WorkflowItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<boolean[]>(
    workflowData.map(() => false)
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(
    null
  );
  const navigate = useNavigate();

  const pageRequest: PageRequest = {
    pageNumber: 1,
    pageSize: 10,
  };

  const fetchData = async () => {
    const response = await getWorkflows(pageRequest);
    if (response) setWorkflowData(response.data);
  };
  const toggleSelectAll = () => {
    const newSelected = !selectedAll;
    setSelectedAll(newSelected);
    setSelectedItems(workflowData.map(() => newSelected));
  };

  const toggleCheckbox = (index: number) => {
    const updatedSelectedItems = [...selectedItems];
    updatedSelectedItems[index] = !updatedSelectedItems[index];
    setSelectedItems(updatedSelectedItems);
  };

  const toggleDropdown = (index: number) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index); // Toggle dropdown visibility
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="p-4 flex-1">
          <div className="flex my-4 justify-between">
            <h1 className="text-3xl mb-4">Workflow Configuration</h1>
            <button
              onClick={() => {
                setModalOpen(true);
              }}
              className="text-black px-10 py-2 border border-[#101828] text-[#101828] font-semibold rounded-lg"
            >
              + Add
            </button>
          </div>

          {isModalOpen && (
            <WorkFlowModal
              isModalOpen={isModalOpen}
              setModalOpen={(value) => setModalOpen(value)}
              onCreateSuccess={fetchData}
            />
          )}

          {/* Search & Filter */}
          <div className="flex justify-between mb-4">
            <div className="flex items-center relative">
              <img
                src={`${process.env.PUBLIC_URL}/assets/icons/outline/search.svg`}
                alt="search"
                className="absolute left-[5px]"
              />
              <input
                type="text"
                placeholder="Search"
                className="border p-2 rounded-lg mr-2 indent-8"
              />
              <button className="p-2">
                <CiFilter size={24} />
              </button>
            </div>
          </div>

          {/* Table */}
          <table className="min-w-full table-auto border-collapse border border-gray-300">
            <thead className="bg-gray-200">
              <tr>
                {/* <th className="border ">
                  <input
                    type="checkbox"
                    checked={selectedAll}
                    onChange={toggleSelectAll}
                    className="form-checkbox text-yellow-500 focus:ring-yellow-400"
                  />
                </th> */}
                <th className="border border-gray-300 px-4 py-2 text-left">
                  Application Type
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left">
                  Number of Stages
                  <FaSort className="inline ml-1 cursor-pointer" />
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left">
                  Status
                  <FaSort className="inline ml-1 cursor-pointer" />
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left">
                  Created Date
                  <FaSort className="inline ml-1 cursor-pointer" />
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {workflowData.map((workflow, index) => (
                <tr key={index}>
                  {/* <td className="border px-4 py-2">
                    <input
                      type="checkbox"
                      checked={selectedItems[index]}
                      onChange={() => toggleCheckbox(index)}
                      className="form-checkbox text-yellow-500 focus:ring-yellow-400"
                    />
                  </td> */}
                  <td className="border border-gray-300 px-4 py-2">
                    {workflowTypeToString(workflow.typeOfWorkflow)}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {workflow.numberOfStages}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    <span
                      className={
                        `border rounded-lg border-gray-300 px-2 py-1 ` +
                        (workflow.status?.toLowerCase() === 'active'
                          ? 'bg-[#42BE65] text-white'
                          : 'bg-[#FA4D56] text-white')
                      }
                    >
                      {workflow.status}
                    </span>
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {workflow.createdDate}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 relative">
                    {/* 3-dots icon for dropdown */}
                    <button
                      onClick={() => toggleDropdown(index)}
                      className="text-gray-600"
                    >
                      <BsThreeDotsVertical />
                    </button>
                    {/* Dropdown menu */}
                    {openDropdownIndex === index && (
                      <div className="absolute right-0 mt-2 w-40 bg-white border border-gray-300 rounded shadow-lg z-10">
                        <ul>
                          <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                            View
                          </li>
                          <li
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => {
                              navigate('/workflow/command', {
                                state: { workflowId: workflow.workflowId },
                              });
                            }}
                          >
                            Command
                          </li>
                          <li
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => {
                              navigate('/workflow/parameter', {
                                state: { workflowId: workflow.workflowId },
                              });
                            }}
                          >
                            Parameters
                          </li>
                          <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-red-500">
                            Delete
                          </li>
                        </ul>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
    </>
  );
};

export default WorkflowConfiguration;
