import React, { useMemo } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  createContact,
  updateContact,
} from '../../../services/supplierService';
import { useGlobalContext } from '../../../core/context/GlobalContext';
import { PrimaryContact } from './PrimaryContact';
import { toast } from 'react-toastify';

interface ModalContactFormProps {
  isOpen: boolean;
  onClose: () => void;
  initialValues?: PrimaryContact | null;
}

interface ContactFormValues {
  firstName: string;
  middleName: string;
  lastName: string;
  designation: string;
  nationality: string;
  email: string;
  phoneNumber: string;
  terms: boolean;
}

// Utility to handle splitting the fullName into parts
const splitFullName = (fullName: string) => {
  const [firstName = '', middleName = '', lastName = ''] = fullName.split(' ');
  return { firstName, middleName, lastName };
};

// Validation schema defined outside for memoization
const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  middleName: Yup.string(),
  lastName: Yup.string().required('Last Name is required'),
  designation: Yup.string().required('Designation is required'),
  nationality: Yup.string().required('Nationality is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phoneNumber: Yup.string().required('Phone Number is required'),
  terms: Yup.boolean().oneOf(
    [true],
    'Please accept the terms and conditions to continue'
  ),
});

const ModalContactForm: React.FC<ModalContactFormProps> = ({
  isOpen,
  onClose,
  initialValues,
}) => {
  const { supplierId } = useGlobalContext();

  const formInitialValues = useMemo<ContactFormValues>(() => {
    if (initialValues) {
      const { firstName, middleName, lastName } = splitFullName(
        initialValues.fullName
      );
      return {
        firstName,
        middleName,
        lastName,
        designation: 'NA',
        nationality: initialValues.nationality || '',
        email: initialValues.email || '',
        phoneNumber: initialValues.phoneNumber || '',
        terms: true,
      };
    }
    return {
      firstName: '',
      middleName: '',
      lastName: '',
      designation: '',
      nationality: '',
      email: '',
      phoneNumber: '',
      terms: false,
    };
  }, [initialValues]);

  const handleSubmit = async (values: ContactFormValues) => {
    try {
      const payload: any = {
        // contactId: values.firstName + ' ' + values.lastName,
        fullName:
          values.firstName + ' ' + values.middleName + ' ' + values.lastName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        companyId: supplierId,
        nationality: values.nationality,
      };

      if (initialValues) {
        payload.contactId = initialValues.contactId;
        const response = await updateContact(payload);
        if (response.isSuccess) {
          toast.success('Contact updated successfully');
          onClose();
        }
      } else {
        const response = await createContact(payload);
        if (response.isSuccess) {
          toast.success('Contact created successfully');
          onClose();
        }
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-2xl shadow-lg  max-w-5xl">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold">Add Primary Contact</h2>
              <button className="text-red-500 text-xl" onClick={onClose}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/close.svg`}
                  alt="close"
                />
              </button>
            </div>

            {/* Divider */}
            <div className="divider my-6 !bg-[#E5E5E5]"></div>

            <Formik
              initialValues={formInitialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form className="flex flex-col gap-x-4 gap-y-6">
                <div className="flex gap-4">
                  <div>
                    <label className="block mb-1">First Name*</label>
                    <Field
                      name="firstName"
                      className="border rounded-lg text-sm p-2 w-[177px] h-[33px]"
                      placeholder="Enter first name"
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div>
                    <label className="block mb-1">Middle Name</label>
                    <Field
                      name="middleName"
                      className="border rounded-lg text-sm p-2 w-[177px] h-[33px]"
                      placeholder="Enter middle name"
                    />
                  </div>

                  <div>
                    <label className="block mb-1">Last Name*</label>
                    <Field
                      name="lastName"
                      className="border rounded-lg text-sm p-2 w-[177px] h-[33px]"
                      placeholder="Enter last name"
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                </div>

                <div className="flex w-full mt-4">
                  <div className="w-full">
                    <label className="block mb-1">Designation*</label>
                    <Field
                      name="designation"
                      className="border rounded-lg text-sm p-2 w-full h-[33px]"
                      placeholder="Enter designation"
                    />
                    <ErrorMessage
                      name="designation"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                </div>

                <div className="flex gap-4 mt-4">
                  <div>
                    <label className="block mb-1">Nationality*</label>
                    <Field
                      as="select"
                      name="nationality"
                      className="border rounded-lg text-sm p-2 w-[177px] h-[33px]"
                    >
                      <option value="">Select nationality</option>
                      <option value="United States">United States</option>
                      <option value="Nigeria">Nigeria</option>
                      <option value="United Kingdom">United Kingdom</option>
                      {/* Add more nationalities */}
                    </Field>
                    <ErrorMessage
                      name="nationality"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div>
                    <label className="block mb-1">Email*</label>
                    <Field
                      name="email"
                      type="email"
                      className="border rounded-lg text-sm p-2 w-[177px] h-[33px]"
                      placeholder="Enter email"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div>
                    <label className="block mb-1">Phone Number*</label>
                    <Field
                      name="phoneNumber"
                      className="border rounded-lg text-sm p-2 w-[177px] h-[33px]"
                      placeholder="Enter phone number"
                    />
                    <ErrorMessage
                      name="phoneNumber"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                </div>

                {/* Divider */}
                <div className="divider my-6 !bg-[#E5E5E5]"></div>

                {/* Terms and Conditions */}
                <div className="">
                  <div className="flex items-center">
                    <Field
                      type="checkbox"
                      name="terms"
                      className="mr-2 w-[15px] h-[15px] bg-primary-500 rounded-lg text-white"
                    />
                    <label htmlFor="terms" className=" text-gray-700 text-sm">
                      I have fully read and understood the Terms and Conditions.
                      I am Authorized by my company to agree and abide by them.
                    </label>
                  </div>
                  <ErrorMessage
                    name="terms"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                {/* Save Button */}
                <div className="flex justify-end mt-4">
                  {/* <button
                    type="button"
                    className="bg-gray-500 text-white px-6 py-2 rounded-md mr-3"
                    onClick={onClose}
                  >
                    Cancel
                  </button> */}
                  <button
                    type="submit"
                    className="bg-primary text-black px-12 h-12 py-2 rounded-lg font-semibold"
                  >
                    {initialValues ? 'Update' : 'Save'}
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalContactForm;
