import React, { useEffect, useState } from 'react';
import { useGlobalContext } from '../../../core/context/GlobalContext';
import { deleteContact, deleteSupplier, getContacts, getSubsidiaries } from '../../../services/supplierService';
import Delete from '../../../shared/utils/Delete/Delete';
import { toast } from 'react-toastify';
import { CompanyRegisterFormValues } from './SupplierRegistration';
import AffiliateModal from './AffilateModal';

export const AffiliateTable = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [affiliateList, setAffiliateList] = useState<CompanyRegisterFormValues[]>([]);
  const [selectedAffiliate, setSelectedAffiliate] = useState<CompanyRegisterFormValues | null>(null);
  const { supplierId } = useGlobalContext();

  const pageRequest = {
    pageNumber: 1,
    pageSize: 10,
  };

  const fetchData = async () => {
    const response = await getSubsidiaries(pageRequest);
    if (response) setAffiliateList(response.data);
  };

  const deleteSelectedAffiliate = async () => {
    if (!selectedAffiliate) return;
    const { companyId } = selectedAffiliate;
    if (!companyId) return;
    const response: any = await deleteSupplier(companyId);
    if (response) {
      toast.success(response?.message);
      setIsDeleteModalOpen(false);
      fetchData();
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
    setSelectedAffiliate(null);
  };
  const editModal = (affiliate: CompanyRegisterFormValues) => {
    setIsModalOpen(true);
    setSelectedAffiliate(affiliate);
  };
  const deleteModal = (affiliate: CompanyRegisterFormValues) => {
    setSelectedAffiliate(affiliate);
    setIsDeleteModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedAffiliate(null);
    fetchData();
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedAffiliate(null);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-bold">Primary Contact*</h2>
        <button
          onClick={openModal}
          className="text-black px-4 py-2 w-24 border border-[#101828] text-[#101828] font-semibold rounded-md"
        >
          + Add
        </button>
      </div>

      <table className="min-w-full table-auto border-collapse border border-gray-300">
        <thead className="bg-gray-200">
          <tr>
            <th className="border border-gray-300 px-4 py-2 text-left">
              {' '}
              Business Name
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left">
              Country
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left">
              Commercial Reg. No
            </th>

            <th className="border border-gray-300 px-4 py-2 text-left">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {affiliateList.map((affiliate) => (
            <tr key={affiliate.companyId}>
              <td className="border border-gray-300 px-4 py-2">
                {affiliate.businessName}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {affiliate.country}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {affiliate.registrationNumber}
              </td>

              <td className="border border-gray-300 px-4 py-2">
                <button
                  className="text-green-500"
                  onClick={() => editModal(affiliate)}
                >
                  Edit
                </button>
                <button
                  className="text-red-500 ml-2"
                  onClick={() => deleteModal(affiliate)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && (
        <AffiliateModal
          isOpen={isModalOpen}
          onClose={closeModal}
          initialValues={selectedAffiliate}
        />
      )}

      {isDeleteModalOpen && (
        <Delete
          title="Are you sure"
          desc="This action is permanent, all data would be lost."
          onDelete={deleteSelectedAffiliate}
          onCancel={closeDeleteModal}
        />
      )}
    </div>
  );
};
