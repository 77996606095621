import React from 'react';
import './AppButton.css';
type AppBtn = {
  title: string | React.ReactNode;
  onClick: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  className?: string;
  outline?: boolean;
  danger?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
};

const AppButton: React.FC<AppBtn> = ({
  title,
  onClick,
  isLoading,
  disabled,
  style,
  className,
  outline,
  danger,
  leftIcon,
  rightIcon,
}) => {
  const getBtnClass = () => {
    let btnClass = 'app-btn';
    if (outline) btnClass += ' outline';
    if (danger) btnClass += ' danger';
    return btnClass;
  };
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      style={style}
      aria-disabled={isLoading}
      className={getBtnClass() + ' ' + className}
    >
      {isLoading ? (
        <span className="loading">Loading...</span>
      ) : (
        <>
          {leftIcon && <span className="icon-left">{leftIcon}</span>}
          <span>{title}</span>
          {rightIcon && <span className="icon-right">{rightIcon}</span>}
        </>
      )}
    </button>
  );
};

export default AppButton;
