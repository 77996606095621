export const modulesData = [
  {
    id: 1,
    imageSrc: `${process.env.PUBLIC_URL}/assets/images/chooseDemo/supplier-icon.svg`,
    moduleTitle: 'Module 1',
    moduleType: 'Information Centre',
    link: 'https://maxfront.com/anp/#',
    external: true,
  },
  {
    id: 2,
    imageSrc: `${process.env.PUBLIC_URL}/assets/images/chooseDemo/operator-icon.svg`,
    moduleTitle: 'Module 2',
    moduleType: 'Supplier Management',
    link: '/dashboard',
    external: false,
  },
  {
    id: 3,
    imageSrc: `${process.env.PUBLIC_URL}/assets/images/chooseDemo/payment-icon.svg`,
    moduleTitle: 'Module 3',
    moduleType: 'Goods and Service Catalogue',
    link: '/supplier-application',
    external: false,
  },
  {
    id: 4,
    imageSrc: `${process.env.PUBLIC_URL}/assets/images/chooseDemo/supplier-icon.svg`,
    moduleTitle: 'Module 4',
    moduleType: 'E-Procurement and Contract Management',
    link: '/supplier-application',
    external: false,
  },
  {
    id: 5,
    imageSrc: `${process.env.PUBLIC_URL}/assets/images/chooseDemo/operator-icon.svg`,
    moduleTitle: 'Module 5',
    moduleType: 'Electronic Documents Management',
    link: '/supplier-application',
    external: false,
  },
  {
    id: 6,
    imageSrc: `${process.env.PUBLIC_URL}/assets/images/chooseDemo/supplier-icon.svg`,
    moduleTitle: 'Module 6',
    moduleType: 'API Gateway',
    link: '/supplier-application',
    external: false,
  },
  {
    id: 7,
    imageSrc: `${process.env.PUBLIC_URL}/assets/images/chooseDemo/payment-icon.svg`,
    moduleTitle: 'Module 7',
    moduleType: 'Intranet Portal',
    link: '/supplier-application',
    external: false,
  },
  {
    id: 8,
    imageSrc: `${process.env.PUBLIC_URL}/assets/images/chooseDemo/operator-icon.svg`,
    moduleTitle: 'Module 8',
    moduleType: 'Customer Support System',
    link: '/supplier-application',
    external: false,
  },
];

export const operatorModulesData = [
  {
    id: 1,
    imageSrc: `${process.env.PUBLIC_URL}/assets/images/chooseDemo/supplier-icon.svg`,
    moduleTitle: 'Module 1',
    moduleType: 'Information Centre',
    link: '/supplier-application',
    external: false,
  },
  {
    id: 2,
    imageSrc: `${process.env.PUBLIC_URL}/assets/images/chooseDemo/operator-icon.svg`,
    moduleTitle: 'Module 2',
    moduleType: 'Supplier Management',
    link: '/supplier-application',
    external: false,
  },
  {
    id: 3,
    imageSrc: `${process.env.PUBLIC_URL}/assets/images/chooseDemo/payment-icon.svg`,
    moduleTitle: 'Module 3',
    moduleType: 'Goods and Service Catalogue',
    link: '/supplier-application',
    external: false,
  },
  {
    id: 4,
    imageSrc: `${process.env.PUBLIC_URL}/assets/images/chooseDemo/supplier-icon.svg`,
    moduleTitle: 'Module 4',
    moduleType: 'E-Procurement and Contract Management',
    link: '/supplier-application',
    external: false,
  },
];
