interface ProgressBarProps {
  percentage: number;
}
const ProgressBar = ({ percentage }: ProgressBarProps) => {
  return (
    <div className="w-full bg-gray-200 rounded-full h-4 mb-4">
      <div
        className="bg-green-500 h-4 rounded-full"
        style={{ width: `${percentage}%` }}
      ></div>
    </div>
  );
};

const ApplicationProgress: React.FC<ProgressBarProps> = ({ percentage }) => {
  return (
    <div className="flex gap-2">
      <ProgressBar percentage={percentage} />
      <span>{percentage}%</span>
    </div>
  );
};

export default ApplicationProgress;
