import React, { useEffect, useState } from 'react';

import { MenuItem, menuItems } from './sidebarData';
import './Sidebar.css';
import { useLocation, useNavigate } from 'react-router-dom';

const Sidebar: React.FC = () => {
  const [selected, setSelected] = useState<number>(1); // Default selected to 'Home'
  const navigate = useNavigate();

  const handleSelect = (id: number, link: string) => {
    setSelected(id);
    navigate(`${link}`);
  };

  const location = useLocation(); // Get the current route

  // Sync the selected state with the current URL on component mount
  useEffect(() => {
    const currentPath = location.pathname;
    const currentItem = menuItems.find((item) => item.link === currentPath);

    if (currentItem) {
      setSelected(currentItem.id); // Update selected based on the current route
    }
  }, [location.pathname]); // Re-run this effect whenever the path changes

  return (
    <div className="sidebar-container ">
      <div className="sidebar-content">
        <h2 className="mb-2">All ESSC modules</h2>
        <ul className="menu-list">
          {menuItems.map((item: MenuItem) => (
            <li
              key={item.id}
              className={`menu-item ${selected === item.id ? 'selected' : ''}`}
              onClick={() => handleSelect(item.id, item.link)}
            >
              <img src={item.icon} alt={item.name} />
              {<span className=" text-[14px]">{item.name}</span>}
            </li>
          ))}
        </ul>
      </div>

      <div className="sidebar-footer">
        <p>© 2024 Your Company. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Sidebar;
