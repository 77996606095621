// SupplierModule.tsx
import React from 'react';
import Module from '../Module';
import { modulesData } from '../index';
import { useTranslation } from 'react-i18next';

const SupplierModule: React.FC = () => {
  const { t } = useTranslation();
  const title = `${t('accessible_modules', 'Accessible Modules')}`;
  const desc = `${t('select_a_module', 'Select a module')}`;
  return <Module title={title} desc={desc} modulesData={modulesData} />;
};

export default SupplierModule;
