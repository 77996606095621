import React from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import RouterConfig from './router/router';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
// import Navbar from './shared/utils/NavBar/Navbar';
import { GlobalProvider, useGlobalContext } from './core/context/GlobalContext';
import { ClipLoader } from 'react-spinners';

// Loader Component that listens to global isLoading state
const GlobalLoader = () => {
  const { isLoading } = useGlobalContext(); // Access the isLoading state from GlobalContext

  return (
    <>
      {isLoading && (
        <div className="spinner-container">
          <ClipLoader size={50} color={'#123abc'} loading={isLoading} />
        </div>
      )}
    </>
  );
};

function App() {
  return (
    <div>
      {/* ToastContainer must be present in the TSX */}
      <ToastContainer pauseOnFocusLoss={false} />
      <GlobalProvider>
        <Router>
          {/* <Navbar/> */}

          <GlobalLoader />
          <RouterConfig />
        </Router>
      </GlobalProvider>
    </div>
  );
}

export default App;
