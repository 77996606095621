import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Sidebar from '../../layouts/SideBar/Sidebar';

const PageNotFound: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center h-screen text-center">
    <h1 className="text-6xl font-bold mb-4">{t('notFound', '404')}</h1>
    <p className="text-xl mb-6">
      {t('pageNotFound', "Oops! The page you're looking for doesn't exist.")}
    </p>
    <Link to="/" className="text-blue-500 underline">
      {t('goBackToHome', 'Go Back to Home')}
    </Link>
  </div>
    
  );
};

export default PageNotFound;
